export interface UserState {
    userList: UserModel[],
    userCount: number,
    userParam: any,
    defaultStartTime: any,
    loading: boolean,
    employeeList: any[],
    loadingEmployee: boolean,
    employeeCount: number,
    loadingSendMail: boolean,
    sendMailResult: string,
    userLogs: any[],
    userLogsCount: number,
    loadingUserLogs: boolean,
}

export const userTypeList = Object.freeze({
    0: 'Staff',
    1: 'Approver',
    2: 'User_Administrator',
    3: 'System_Administrator',
})

export const userStatusList = Object.freeze({
    0: 'Disabled',
    1: 'Enabled',
    2: 'Created'
})

export interface JobDivisionPayLocation {
    JobDivisionPayLocationId: number
    PayLocation: string
    Division: string
    Active?: boolean
}

export interface PayLocation {
    Name: string
}