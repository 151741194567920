export interface DashboardState {
    dropdownSite: Equipment[]
    action?: Actions
    timeSheets?: TimeSheetHeader[]
    timeSheetEvents?: [],
    countNotiUnRead?: number,
    countTimeSheetStatus: CountTimeSheetStatus
    filterTimeSheet: FilterTimeSheet,
    loading: boolean,
    isEditAddTask: boolean,
    totalHour: number,
}

export enum Actions {
    SAVE = 'SAVE',
    UPDATE = 'UPDATE',
    UPDATE_CONFIRM = 'UPDATE_CONFIRM',
    CONFIRM = 'CONFIRM',
}

export interface CountTimeSheetStatus {
    totalDraft: number,
    total: number,
    totalApproved: number,
    totalPendingApproved: number,
    totalCanceled: number,
    totalDeclined: number,
}

export const timeSheetStatusName = Object.freeze({
    0: 'Draft',
    1: 'Pending',
    2: 'Approved',
    3: 'Declined',
    4: 'Recalled',
    5: 'Cancelled',
})

export interface FilterTimeSheet {
    fromDay?: string,
    toDay?: string,
    timeSheetStatus?: number,
    timeSheetId?: string,
    divisionJob?: string,
}

export const SET_DROPDOWN_SITE = 'SET_DROPDOWN_SITE'
export const SET_ACTION_TIME_SHEET = 'SET_ACTION_TIME_SHEET'
export const SET_LIST_TIME_SHEET = 'SET_LIST_TIME_SHEET'
export const SET_COUNT_TIME_SHEET_STATUS = 'SET_COUNT_TIME_SHEET_STATUS'
export const SET_LIST_TIME_SHEET_EVENT = 'SET_LIST_TIME_SHEET_EVENT'
export const SET_COUNT_NOTI_UNREAD = 'SET_COUNT_NOTI_UNREAD'
export const SET_FILTER_TIME_SHEET = 'SET_FILTER_TIME_SHEET'
export const SET_LOADING_NOTI = 'SET_LOADING_NOTI'
export const SET_IS_EDIT_ADD_TASK = 'SET_IS_EDIT_ADD_TASK'
export const SET_TOTAL_HOUR_TIME_SHEET = 'SET_TOTAL_HOUR_TIME_SHEET'