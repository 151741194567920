export const AUTH_TOKEN_KEY = window.location.href.includes('timesheets.emecogroup') ? 'access-token' : 'dev-access-token'
export const ID_TOKEN_KEY = 'id-token'
export const REFRESH_TOKEN_KEY = 'refresh-token'
export const EXPIRE_TIME_KEY = 'expire-time'
export const LOGIN_ID_KEY = 'login-id'
export const TOKEN_EXPIRATION_TIME = 60 * 30
export const ID_TOKEN_EXPIRATION_TIME = 60 * 60
export const REFRESH_TOKEN_EXPIRATION_TIME = 60 * 60
export const DEFAULT_PAGE_SIZE = 10
export const seconds = 60
export const USER_TYPE_STAFF = 'Staff'
export const USER_TYPE_APPROVER = 'Approver'
export const USER_TYPE_SYSTEM_ADMINISTRATOR = 'System_Administrator'
export const USER_TYPE_USER_ADMINISTRATOR = 'User_Administrator'
export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';
export const AUTO_THEME = 'auto';

export enum TASK_TYPES {
    Rental='Rental',
    JobCost='JobCost',
    UnBilled='UnBilled',
  }
  
export enum MESSAGE_CODES {
  MSG_SAVE_SUCCESS = 'Saved successfully',
  MSG_DELETE_SUCCESS = 'Deleted successfully',
  MSG_PLEASE_SELECT = 'Please select file to upload',
  ERR_SERVER = 'Internal server error',
  MSG_PLEASE_SELECT_USER = 'Please select user',
  MSG_EMAIL_EXIST = 'The specified email is being used by another account. Please use a different email.',
  MSG_AVATAR_TYPE = 'Avatar picture must be JPG/PNG format!',
  MSG_AVATAR_SIZE = 'Avatar picture size can not exceed 5MB!',
  MSG_AVATAR_IS_NOT_SQUARE = 'Avatar must be the square shape!',
  
}

export enum MESSAGE_DIALOG_CONFIRM {
  MSG_DIALOG_LEVEL_1 = "Want to",
  MSG_DIALOG_LEVEL_2 = "Are you sure you want to",
  MSG_DIALOG_DEFAULT_LEVEL_1 = "Deleting",
  MSG_DIALOG_DEFAULT_LEVEL_2 = "Your data will be deleted. Are you sure?",
  MSG_DIALOG_BTN_NO = "No",
  MSG_DIALOG_BTN_DELETE = "Delete",

}

export enum DATA_FORMAT {
  DATE_TIME_FM_1 = 'MMM DD, YYYY HH:mm'
}

export enum USER_TYPE {
  STAFF = 0,
  APPROVER = 1,
  USER_ADMINISTRATOR = 2,
  SYSTEM_ADMINISTRATOR = 3,
}

export enum TASK_TYPES_VALUE {
  All = 0,
  Rental = 1,
  JobCost = 2,
}

export const TASK_TYPE_LISTS = [
  {
    Label: 'All',
    Value: TASK_TYPES_VALUE.All
  },
  {
    Label: 'Rental',
    Value: TASK_TYPES_VALUE.Rental
  },
  {
    Label: 'Job Cost',
    Value: TASK_TYPES_VALUE.JobCost
  }
]