import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, Method, AxiosError } from 'axios'
import {
  AUTH_TOKEN_KEY,
  ID_TOKEN_KEY,
  LOGIN_ID_KEY,
  EXPIRE_TIME_KEY,
  REFRESH_TOKEN_KEY,
} from './constants'
import env from './env'
import store from 'store'
import { loginRequest, msalInstance, tokenRequest } from "../authConfig";
import { autoRefreshToken, checkTokenExpire, isAuthenticated } from '../utils'
import { InteractionType } from '@azure/msal-browser'

// const { cookies } = useCookies()

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    if (error?.response.status === 403 && error?.response.data.message === "Your account has been disabled. Contact your support person to enable it, then try again.") {
      localStorage.setItem(AUTH_TOKEN_KEY, '');
      msalInstance.logoutRedirect()
    }

    if (error?.response.status === 403 && !window.location.pathname.includes('login')) {
      return store.commit('SET_OPEN_POPUP_TIMEOUT', true)
    }

    if (error?.response.status === 401) {
      autoRefreshToken()
    }

    return Promise.reject(error)
  },
)
axios.defaults.headers.post['Content-Type'] = 'application/json'

function toQueryString(params: { [key: string]: any } = {}) {
  const keys: string[] = Object.keys(params || {})
  const segments: string[] = []
  keys.forEach((k) => {
    if (params[k] || params[k] === 0) {

      segments.push(`${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    }
  })

  return segments.join('&')
}

export interface ServiceOptions {
  namespace?: string
  scope?: string
  endpoint?: string
  axios?: AxiosRequestConfig
}

const defaultOptions: ServiceOptions = {
  namespace: undefined,
  endpoint: env('VITE_SERVICE_ENDPOINT'),
  axios: {},
}

export default class Service {
  axios: AxiosInstance

  private headers: any

  private options: ServiceOptions = { ...defaultOptions }

  private token: string | null = ''

  /**
   * Creates an instance of Service.
   *
   * @memberOf Service
   */
  constructor(options?: ServiceOptions) {
    this.options = { ...defaultOptions, ...options }
    const baseURL = this.options.endpoint || env('VITE_SERVICE_ENDPOINT')
    this.axios = axios.create({
      baseURL,
      ...this.options.axios,
    })
    // this.token = cookies.get(AUTH_TOKEN_KEY)
  }

  /**
   * Call a service action via REST API
   *
   * @param {any} action  name of action
   * @param {any} params  parameters to request
   * @returns  {Promise}
   *
   * @memberOf Service
   */
  public async rest<P>(
    action: string,
    params?: P,
    options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
    },
  ) {
    const { headers } = options
    try {
      this.token = localStorage.getItem(AUTH_TOKEN_KEY)
      const isTokenExpire = checkTokenExpire(this.token);

      const request = {
        ...loginRequest,
      };

      if(isTokenExpire){
        await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );
        const response = await msalInstance.acquireTokenSilent({
          ...tokenRequest,
        });
        this.token = response.accessToken
        localStorage.setItem(AUTH_TOKEN_KEY, response.accessToken);
      }


      const opts: AxiosRequestConfig = {
        url: action.startsWith("http") ? action : `${env('VITE_SERVICE_ENDPOINT')}${action}`,
        method: options.method as Method,
        data: JSON.stringify(params),
        headers: {
          ...(this.headers || {}),
          ...headers,
          Authorization: this.token ? `Bearer ${this.token}` : '',
        },
      }
      const response = await axios.request(opts)
      return response.data
    } catch (err) {
      throw (err as AxiosError).response
    }
  }

  public get<P = any>(action: string, params?: P, options: any = {}) {
    const { headers = {} } = options
    let query = ''
    if (params) {
      query = toQueryString(params)
    }
    const path = query ? `${action}?${query}` : action
    return this.rest(
      path,
      {},
      {
        method: 'get',
        headers,
      },
    )
  }

  public post(action: string, params?: any, options: any = {}) {
    const { headers = {} } = options
    return this.rest(action, params, {
      method: 'post',
      headers,
    })
  }

  public put(action: string, params?: any, options?: any) {
    return this.rest(action, params, {
      method: 'put',
      baseURL: `${env('VITE_SERVICE_ENDPOINT')}`,
      ...options,
      headers: {
        Authorization: this.token ? `Bearer ${this.token}` : '',
        'Content-Type': 'application/json',
      },
    })
  }

  public patch(action: string, params?: any, options?: any) {
    return this.rest(action, params, {
      method: 'Patch',
      baseURL: `${env('VITE_SERVICE_ENDPOINT')}`,
      ...options,
      headers: {
        Authorization: this.token ? `Bearer ${this.token}` : '',
        'Content-Type': 'application/json',
      },
    })
  }
  public delete(action: string, params?: any, options: any = {}) {
    const { headers = {} } = options
    const query = toQueryString(params)
    const path = query ? `${action}/${query}` : action
    return this.rest(
      path,
      {},
      {
        method: 'delete',
        headers,
      },
    )
  }

  /**
   * POST FORM DATA FILE
   * @param action 
   * @param data 
   * @param options 
   */
  public async postFormData(action: string, data: any, options: any = {}) {
    this.token = localStorage.getItem(AUTH_TOKEN_KEY)
    var config = {
      method: 'POST',
      url: `${env('VITE_SERVICE_ENDPOINT')}${action}`,
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: this.token ? `Bearer ${this.token}` : '',
      },
      data: data
    };

    return await axios(config).then(function (response) {
      if (response.status == 200 || response.status == 201) {
        return response.data;
      }
    })
      .catch(function (error) {
        throw (error as AxiosError).response
      });

  }

  /**
   * Download file function
   * @param action 
   * @param params 
   * @param options 
   * @returns 
   */
  public async downloadFile<P = any>(action: string, params?: P, options: any = {}) {
    this.token = localStorage.getItem(AUTH_TOKEN_KEY)
    var config = {
      method: 'GET',
      url: `${env('VITE_SERVICE_ENDPOINT')}${action}`,
      ...options,
      headers: {
        Authorization: this.token ? `Bearer ${this.token}` : '',
      },
      // responseType: 'blob',
    };

    return await axios(config)

      .catch(function (error) {
        throw (error as AxiosError).response
      });

  }
  // public async renewToken() {
  //   store.commit('SET_LOADING', true)
  //   return axios
  //     .post(`${env('VITE_SERVICE_ENDPOINT')}/admin/renew-token`, {
  //       loginId: cookies.get(LOGIN_ID_KEY),
  //       id_token: cookies.get(ID_TOKEN_KEY),
  //       refresh_token: cookies.get(REFRESH_TOKEN_KEY),
  //     })
  //     .then((res) => {
  //       if (res.data.data) {
  //         const { data } = res.data
  //         store.dispatch('auth/actSetToken', {
  //           idToken: data.id_token,
  //           accessToken: data.access_token,
  //           refreshToken: data.refresh_token,
  //         })
  //         this.token = cookies.get(AUTH_TOKEN_KEY)
  //       }
  //     })
  //     .catch(() => store.dispatch('auth/actLogout'))
  //     .finally(() => store.commit('SET_LOADING', false))
  // }
}
