import { AUTH_TOKEN_KEY } from "core/constants";
import { RootState } from "store";
import { ActionTree } from "vuex";
import { AuthService } from "../service";
import { AuthState, CurrentUserModel, SET_USER } from "./types";
const service = new AuthService()
export const actions: ActionTree<AuthState, RootState> = {
  actClearToken({ commit }) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  },
  actSetToken({ commit }, token: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  },
  async actGetMe({commit}){
    try {
      const currentUser = await service.me()
      commit(SET_USER, currentUser)
      return currentUser
    } catch (error) {
      return null
    }
  },
};
