﻿import moment from 'moment';

export const getHoursAndMinutes = (totalMinutes : number, timesheetLineMinutes?: number) => {
  if (totalMinutes == null) {
    return {
      hours: 12,
      minutes: 0,
      isAm: true,
      isInit: true    
    }
  }
  if (timesheetLineMinutes == 0 && totalMinutes == 0) {
    return {
      hours: 12,
      minutes: 0,
      isAm: true,
      isInit: true    
    }
  }
  if (totalMinutes == 1440 || totalMinutes == 0) {
    return {
      hours: 12,
      minutes: 0,
      isAm: true,
      isInit: false
    }
  }
  if (totalMinutes < 60) {
    return {
      hours: 12,
      minutes: totalMinutes % 60,
      isAm: true,
      isInit: false
    }
  }

  if (Math.floor(totalMinutes / 60) > 12) {
    return {
      hours: Math.floor(totalMinutes / 60),
      minutes: totalMinutes % 60,
      isAm: Math.floor(totalMinutes / 60) < 12,
      isInit: false
    }
  }

  return {
    hours: Math.floor(totalMinutes / 60),
    minutes: totalMinutes % 60,
    isAm: Math.floor(totalMinutes / 60) < 12,
    isInit: false
  }
}

export function convertMinutesToHours(minutes: number) {
  let hours = minutes / 60;
  return parseFloat(hours.toFixed(2));
}

export const formatDate = (date: string) => {
  return moment(date).format('hh:mm, DD MMM YYYY')
}

export const formatDateLocale = (date: string) => {
  return moment(date).format('DD/MM/YYYY hh:mm')
}

export const formatOnlyDate = (date: string) => {
  const parsedDate = moment(date, 'MMM D, YYYY');
  return parsedDate.format('DD/MM/YYYY')
}

export const formatOnlyHours = (totalHours: number) => {
  // 1.1 -> 01h01m
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  return `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}m`;
}

export const getTimezone = () => {
  const currentDate = new Date();
  const timezoneOffset = currentDate.getTimezoneOffset();
  const hours = Math.abs(Math.floor(timezoneOffset / 60));
  return timezoneOffset >= 0 ? -hours : hours;
}
