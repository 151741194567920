import {
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { AUTH_TOKEN_KEY, TASK_TYPES } from "core/constants";
import { msalInstance, tokenRequest } from "../authConfig";
import { getHoursAndMinutes } from "./time";

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
        return instance
          .loginPopup(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      } else if (interactionType === InteractionType.Redirect) {
        return instance
          .loginRedirect(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }

      return false;
    })
    .catch(() => {
      return false;
    });
}

export function checkTokenExpire(token: string | null) {
  if (!token) {
    return true;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const jwtPayload = JSON.parse(jsonPayload);
  if (jwtPayload.exp < Date.now() / 1000) {
    return true;
  } else {
    return false;
  }
}

export function getTaskTypId(taskType: TASK_TYPES) {
  switch (taskType) {
    case TASK_TYPES.Rental:
      return 0;
    case TASK_TYPES.UnBilled:
      return 1;
    case TASK_TYPES.JobCost:
      return 2;
    default:
      return 0;
  }
}


export function convertTaskIdToType(taskTypeId: number) {
  switch (taskTypeId) {
    case 0:
      return TASK_TYPES.Rental.toString();
    case 1:
      return TASK_TYPES.UnBilled.toString();
    case 2:
      return TASK_TYPES.JobCost.toString();
    default:
      return TASK_TYPES.JobCost.toString();
  }
}

export function convertTaskIdToTypeText(taskTypeId: number) {
  switch (taskTypeId) {
    case 0:
      return 'Rental';
    case 1:
      return 'Unbilled';
    case 2:
      return 'Job Cost';
    default:
      return 'Job Cost';
  }
}


export function convertTaskIdToVisbleType(taskTypeId: number) {
  switch (taskTypeId) {
    case 0:
      return 1
    case 1:
      return 2
    case 2:
      return 2
    default:
      return null
  }
}


export function formatTime(timeVal: string): TimeObject{
  let timeObj: TimeObject = {
    hour: 0,
    minutes: 0,
    isAm: true,
    hours: 0,
  };
  
  if(timeVal){
    const newStr = timeVal.replace('PT', '').replace('M', '').replace('H', ':').replace('S','');
    let [strHour, strMins] = newStr.split(':');
    if (!strMins) {
      strMins = '0'
    }
    if(strHour && strMins){
      timeObj.hours = Number(strHour);
      timeObj.minutes = Number(strMins);
      let isAm  = true;
      if(timeObj.hours > 12){
        isAm = false;
        timeObj.hour = timeObj.hours - 12;
      }else{
        timeObj.hour = timeObj.hours;
      }
      timeObj.isAm = isAm;
    }
  }

 return timeObj;
}

export function convertStrToTime (timeVal: any){
  let str = 'PT00H00M';
  //12:00:00.00
  if(timeVal){
    const [strFullTime, strmiliSec] = timeVal.split('.');
    if(strFullTime){
      const [hour, min, sec] = strFullTime.split(':');
      const value = `PT${hour.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}H${min.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}M`;
      str = value;
    }
  }

  return str;
}

export function getClassByStatusTimeSheet(status: number) {
  switch (status) {
    case 0:
      return 'status-draff';
    case 1:
        return 'status-pending';
    case 2:
      return 'status-approve';
    case 3:
      return 'status-declined';
    case 5:
        return 'status-cancel';
    default:
      return 'status-draff';
  }
}

export function setTimeStart(startTime: number, startDate: string) {
  const hourAndMinutes = getHoursAndMinutes(startTime);
  const hour = hourAndMinutes.hours.toString().padStart(2, '0');
  const minute = hourAndMinutes.minutes.toString().padStart(2, '0');
  return `${hour}:${minute}, ${startDate}`
}

export function convertStatusTimeSheet(status?: number) {
  switch (status) {
    case 0:
      return 'Draft';
    case 1:
        return 'Pending Approval';
    case 2:
      return 'Approved';
    case 3:
      return 'Declined';
    case 5:
        return 'Cancelled';
    default:
      return 'Draft';
  }
}

export async function autoRefreshToken () {
  const response = await msalInstance.acquireTokenSilent({
    ...tokenRequest,
  });
  localStorage.setItem(AUTH_TOKEN_KEY, response.accessToken);
}

export async function clearCookie () { 
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
        var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        var p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
        };
        d.shift();
    }
  }
}
