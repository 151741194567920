<template>
  <el-popover :disabled="disable" placement="bottom" @before-leave="handleBeforeLeave" :width="240" trigger="click"
    popper-class="time-picker-popper" ref="refElPopupOverInputTime">
    <template #reference>
      <div class="
          time-picker-component
          h-[40px]
          flex
          items-center
          justify-center
          min-w-[150px]
        " :class="disable ? 'time-picker-component-is-disable' : 'cursor-pointer'">
        <img src="../assets/icon_clock.svg" height="16" width="16" class="ml-[18px] mr-[18px]" />
        <span>{{ (isInit ? '--' : (orgHour < 10 ? `0${orgHour}` : (orgHour % 12 != 0 ? orgHour % 12 : selectIsAm ? 12 :
          12))) }}:</span>
            <span>{{ (isInit ? '--' : (orgMinute < 10 ? `0${orgMinute}` : orgMinute)) }}</span>
                <span class="ml-[5px]">{{ selectIsAm ? "AM" : "PM" }}</span>
                <el-icon class="ml-[20px] mr-[20px]">
                  <ArrowDown />
                </el-icon>
      </div>
    </template>
    <div class="p-[8px]">
      <div class="grid grid-flow-row auto-rows-max">
        <div class="font-medium text-sm mb-[8px] lable-popup-time">Enter Time</div>
        <div class="grid grid-flow-col auto-cols-max">
          <div class="mr-[8px]">
            <div class="flex flex-col">
              <div>
                <input class="h-[64px] w-[64px] font-normal text-center time-picker-component-input" type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');  if(Number(this.value)>12) this.value=12;"
                  :value="orgHour <= 12 ? orgHour : orgHour == 24 ? 12 : orgHour % 12" onclick="this.select()"
                  @change="handleSubmitTime($event, true)" />
              </div>
              <div class="mt-[6px] text-sm font-semibold lable-popup-time">
                <span>Hours</span>
              </div>
            </div>
          </div>
          <p class="font-normal text-center lable-popup-time" style="font-size: 36px">:</p>
          <div class="ml-[8px]">
            <div class="flex flex-col">
              <div>
                <input class="h-[64px] w-[64px] font-normal text-center time-picker-component-input" type="text"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'); if(Number(this.value)>59) this.value=59;"
                  :value="minutes" @change="handleSubmitTime($event, false)" onclick="this.select()" />
              </div>
              <div class="mt-[6px] text-sm font-semibold lable-popup-time">
                <span>Minute</span>
              </div>
            </div>
          </div>
          <div class="grid grid-flow-row auto-rows-max ml-[12px]">
            <div style="border-radius: 4px"
              class="cursor-pointer flex items-center justify-center w-[39px] h-[28px] time-picker-component-zone" :style="{
                background: selectIsAm ? '#ED1C2D' : '',
              }" v-on:click="selectAMPM('AM')">
              <p
                :class="selectIsAm ? 'time-picker-component-zone-text-selected' : 'time-picker-component-zone-text-un-selected'">
                AM</p>
            </div>
            <div style="border-radius: 4px" class="
              cursor-pointer
               flex
               items-center
               justify-center
               w-[39px]
               h-[28px]
               mt-[8px]
               time-picker-component-zone
             " :style="{
               background: !selectIsAm ? '#ED1C2D' : '',
             }" v-on:click="selectAMPM('PM')">
              <p
                :class="!selectIsAm ? 'time-picker-component-zone-text-selected' : 'time-picker-component-zone-text-un-selected'">
                PM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin: 0px; padding-bottom: 8px; padding-top: 12px;">
      <el-button class="btn-cancel" @click="handleCancel">Cancel</el-button>
      <el-button class="btn-apply" type="primary" style="background-color: #246bfd" @click="submitTime">Apply</el-button>
    </div>
  </el-popover>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import { showError } from "../utils/notifications";

export default defineComponent({
  name: "TimePicker",
  props: {
    hours: Number,
    minutes: Number,
    isAm: Boolean,
    disable: Boolean,
    isInit: Boolean,
    additionValues: Object,
    changeValidTime: Boolean
  },
  components: {
    ArrowDown,
  },
  setup(props, ctx) {
    const store = useStore();
    const hours = ref<number | undefined>(props.hours)
    const minutes = ref<number | undefined>(props.minutes)
    const orgHour = ref<number>(props.hours ?? 0)
    const orgMinute = ref<number>(props.minutes ?? 0)
    const currentTheme = computed(() => store.state.currentTheme);
    const selectIsAm = ref<boolean>(props.isAm);
    const orgSelectIsAm = ref<boolean>(props.isAm);
    const isInit = ref<boolean>(props.isInit);

    const selectAMPM = (type: string) => {
      if (type == 'AM') {
        selectIsAm.value = true
      }
      if (type == 'PM') {
        selectIsAm.value = false
      }
    };
    const refElPopupOverInputTime = ref<any>(null)
    const disable = ref<boolean>(props.disable)

    const submitTime = () => {
      if ((hours.value != undefined && minutes.value != undefined)) {
        if (hours.value == 0) {
          hours.value = 12
        }

        if (!selectIsAm.value) {
          hours.value = hours.value % 12 + 12
        } else {
          hours.value = hours.value <= 12 ? hours.value : hours.value == 24 ? 12 : hours.value % 12
        }

        let hourSubmit = hours.value;

        const dataSubmit = {
          hours: hourSubmit,
          minutes: minutes.value,
          isAm: selectIsAm.value,
          strTime: `${hourSubmit < 10 ? '0' + hourSubmit : hourSubmit + ''}:${minutes.value < 10 ? '0' + minutes.value : minutes.value + ''}:00`
        }
        orgHour.value = hours.value;
        orgMinute.value = minutes.value;
        orgSelectIsAm.value = selectIsAm.value;
        ctx.emit('submit', dataSubmit, props.additionValues)
        refElPopupOverInputTime.value?.hide()
      }


    }
    const handleSubmitTime = (event: any, isUpdateHours: boolean) => {
      if (isUpdateHours) {
        hours.value = parseInt(event.target.value) || 12;
        orgHour.value = parseInt(event.target.value) == 0 ? 12 : parseInt(event.target.value) || 12;
        // if (selectIsAm.value && hours.value == 12) {
        //   selectIsAm.value = false
        // }

        // if (!selectIsAm.value && hours.value == 0) {
        //   selectIsAm.value = true
        // }
      }
      else {
        minutes.value = parseInt(event.target.value) || 0;
      }

    }

    const handleCancel = () => {
      hours.value = orgHour.value;
      minutes.value = orgMinute.value;
      selectIsAm.value = orgSelectIsAm.value;
      refElPopupOverInputTime.value?.hide()
    }

    const handleBeforeLeave = () => {
      hours.value = orgHour.value;
      minutes.value = orgMinute.value;
      selectIsAm.value = orgSelectIsAm.value;
    }

    watch(props, () => {
      hours.value = props?.hours ?? 12;
      orgHour.value = props?.hours ?? 12;
      minutes.value = props?.minutes ?? 0;
      orgMinute.value = minutes?.value ?? 0;
      selectIsAm.value = props.isAm;
      orgSelectIsAm.value = props.isAm;
      isInit.value = props.isInit;
    });

    return {
      selectIsAm,
      currentTheme,
      selectAMPM,
      submitTime,
      minutes,
      hours,
      handleSubmitTime,
      refElPopupOverInputTime,
      handleCancel,
      disable,
      orgHour,
      orgMinute,
      handleBeforeLeave,
      isInit
    };
  },
});
</script>
<style scoped lang="scss">
.time-picker {
  border-radius: 6px;

  .light {
    box-shadow: 0px 8px 12px rgb(31 35 53 / 15%);
    border: 1px solid rgba(35, 31, 32, 0.1);
    background: #fff;
  }

  .dark {
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.46);
    border: 1px solid #3A4056;
    background: #262A34;
  }
}

.btn-apply {
  padding: 10px 20px;
  border: none;

  &:hover,
  &:focus {
    color: white !important;
  }
}

.btn-cancel {
  padding: 10px 20px;
}
</style>