import { AxiosError } from 'axios';
import Service from 'core/service'

const PAGE_SIZE = 25;
export class DashboardService extends Service {
  createTimeSheet(data: TimeSheetHeader){
    return this.post('/Timesheet',data)
  }

  fetchTimeSheet(expand?: string, filter?: string, pageSize: number = PAGE_SIZE, currentPage: number = 1){
    let params: any = {
      '$orderby': 'CreatedOn desc'
    };
    if (expand) {
      params["$expand"] = expand;
    }
    if(filter){
      params["$filter"] = filter;
    }
    params["$top"] = pageSize;
    params["$skip"] = currentPage == 1 ? 0 : (currentPage - 1) * pageSize;
    return this.get("/Timesheet", params);
  }

  fetchTimeSheetById(id:number, expand?: string, select?: string){
    let params: any = {
    };
    if (expand) {
      params["$expand"] = expand;
    }
    if (select) {
      params["$select"] = select;
    }
    return this.get(`/Timesheet(${id})`, params);
  }

  countTimeSheetByStatus(filter?: string){
    let params: any = {
      $count: true
    };
    params["$top"] = 0
    if (filter) {
      params["$filter"] = filter;
    }
    return this.get("/Timesheet", params);
  }

  updateTimeSheet(dataUpdate: any){
    return this.patch(`/Timesheet(${dataUpdate.Id})`, dataUpdate)
  }

  fetchTimesheetEvent(expand?: string, filter?: string){
    let params: any = {
      '$orderby': 'TimesheetEventDateTime desc'
    };
    if (expand) {
      params["$expand"] = expand;
    }
    if (filter) {
      params["$filter"] = filter;
    }
    return this.get("/TimesheetEvent", params);
  }

  async fetchAllTimesheet(expand: string, filterStr?: string, params?: any){
    const response = await this.fetchTimeSheet(expand, filterStr, PAGE_SIZE, params?.page ?? 1);
    return response.value;
  }

  async uploadSignature (formData: any): Promise<any> {
    try {
      return this.postFormData("/Timesheet/UploadSignature", formData);

    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  async getBase64FromUrl(url: string) {
    const data = await this.get(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async fetchTotalTimesheet(selectProps: string, filter?: string, pageSize: number = 100, currentPage: number = 1){
    let params: any = {
      '$orderby': 'CreatedOn desc',
      $select: selectProps,
    };
    if(filter){
      params["$filter"] = filter;
    }
    params["$top"] = pageSize;
    params["$skip"] = currentPage == 1 ? 0 : (currentPage - 1) * pageSize;
    return this.get("/Timesheet", params);
  }
}

