<template>
  <svg
    v-if="name === 'home'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5492 2.53318C11.3872 1.82618 12.6128 1.82618 13.4508 2.53318L20.2008 8.22772C20.7076 8.65523 21 9.28447 21 9.94747V19.7504C21 20.7169 20.2165 21.5004 19.25 21.5004H16.25C15.2835 21.5004 14.5 20.7169 14.5 19.7504V14.7504C14.5 14.3362 14.1642 14.0004 13.75 14.0004H10.25C9.83579 14.0004 9.5 14.3362 9.5 14.7504V19.7504C9.5 20.7169 8.7165 21.5004 7.75 21.5004H4.75C3.7835 21.5004 3 20.7169 3 19.7504V9.94747C3 9.28447 3.29241 8.65523 3.79916 8.22772L10.5492 2.53318Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'logo'"
    :width="width"
    :height="height"
    viewBox="0 0 140 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2502_3035)">
      <path
        d="M20.7436 42C9.30545 42 0 32.5791 0 20.9994C0 9.42007 9.30545 -0.000572205 20.7436 -0.000572205C26.2654 -0.000572205 31.4616 2.17076 35.3758 6.11361L36.57 7.31704L34.1865 9.73007L32.7257 8.25158C29.4801 5.12181 25.2407 3.40286 20.7436 3.40286C11.1593 3.40286 3.36165 11.2964 3.36165 20.9994C3.36165 30.7025 11.1593 38.5963 20.7436 38.5963C25.4049 38.5963 29.7823 36.7534 33.0692 33.4074L35.4531 35.8068C31.5305 39.8005 26.3066 42 20.7436 42Z"
        :fill="color_1"
      />
      <path
        d="M20.7443 36.4863C12.3096 36.4863 5.44727 29.5391 5.44727 20.9999C5.44727 12.4611 12.3096 5.51364 20.7443 5.51364C24.8107 5.51364 28.6389 7.11109 31.5237 10.012L32.7207 11.2149L21.8587 22.2115L19.4813 19.805L27.8751 11.3078C25.8258 9.75643 23.3473 8.91684 20.7443 8.91684C14.1631 8.91684 8.80891 14.3374 8.80891 20.9999C8.80891 27.6628 14.1631 33.0831 20.7443 33.0831V36.4863Z"
        :fill="color_1"
      />
      <path
        d="M21.7403 14.5084L24.4562 11.7588C23.2926 11.2798 22.0369 11.0277 20.7433 11.0277C15.3118 11.0277 10.8926 15.5013 10.8926 21H14.2547C14.2547 17.3778 17.1654 14.4309 20.7433 14.4309C21.0803 14.4309 21.4133 14.4577 21.7403 14.5084Z"
        :fill="color_1"
      />
      <path
        d="M58.5467 27.4619V32.7285H43.418V8.79067H58.3778V14.0575H48.8225V18.0242H57.533V23.2222H48.8225V27.4619H58.5467Z"
        :fill="color_2"
      />
      <path
        d="M85.7285 22.2298V32.7285H80.6611V22.6756C80.6611 20.9657 79.8508 19.8712 78.2296 19.8712C76.5411 19.8712 75.5949 21.0672 75.5949 23.0849V32.7285H70.5281V22.6756C70.5281 20.9657 69.7172 19.8712 68.0954 19.8712C66.4069 19.8712 65.4607 21.0672 65.4607 23.0849V32.7285H60.3945V15.6307H65.4607V17.2039C66.238 16.04 67.7245 15.1518 69.9883 15.1518C71.9804 15.1518 73.467 15.9718 74.4119 17.4085C75.3581 16.04 76.912 15.1518 79.3104 15.1518C83.196 15.1518 85.7285 17.9556 85.7285 22.2298Z"
        :fill="color_2"
      />
      <path
        d="M99.7162 22.4025C99.1752 20.4185 97.6544 19.7013 96.1348 19.7013C94.2094 19.7013 92.9249 20.6579 92.4535 22.4025H99.7162ZM96.6079 28.6271C97.9928 28.6271 99.1752 28.0786 99.8503 27.3269L103.903 29.6865C102.249 32.0114 99.7487 33.209 96.5405 33.209C90.7633 33.209 87.1836 29.2755 87.1836 24.1803C87.1836 19.0863 90.8318 15.1531 96.1679 15.1531C101.101 15.1531 104.748 19.0164 104.748 24.1803C104.748 24.8985 104.68 25.5821 104.546 26.2322H92.554C93.1959 28.0105 94.7498 28.6271 96.6079 28.6271Z"
        :fill="color_2"
      />
      <path
        d="M105.637 24.1803C105.637 19.0863 109.453 15.1531 114.588 15.1531C117.863 15.1531 120.77 16.8975 122.222 19.5303L117.797 22.1296C117.223 20.932 116.007 20.2139 114.521 20.2139C112.324 20.2139 110.703 21.8218 110.703 24.1803C110.703 26.54 112.324 28.1479 114.521 28.1479C116.007 28.1479 117.256 27.4296 117.797 26.2322L122.222 28.7968C120.77 31.4641 117.898 33.209 114.588 33.209C109.453 33.209 105.637 29.2755 105.637 24.1803Z"
        :fill="color_2"
      />
      <path
        d="M134.932 24.1803C134.932 21.7521 133.243 20.1458 131.047 20.1458C128.851 20.1458 127.162 21.7521 127.162 24.1803C127.162 26.6081 128.851 28.216 131.047 28.216C133.243 28.216 134.932 26.6081 134.932 24.1803ZM122.096 24.1803C122.096 19.0863 126.081 15.1531 131.047 15.1531C136.013 15.1531 139.999 19.0863 139.999 24.1803C139.999 29.2755 136.013 33.209 131.047 33.209C126.081 33.209 122.096 29.2755 122.096 24.1803Z"
        :fill="color_2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2502_3035">
        <rect width="140" height="42" :fill="color_2" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'eclipse'"
    :width="width"
    :height="height"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#ED1C2D" />
    <circle cx="4" cy="4" r="4" fill="#ED1C2D" fill-opacity="0.4" />
  </svg>

  <svg
    v-else-if="name === 'manuals'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2V8C12 9.10457 12.8954 10 14 10H20V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H12ZM13.5 2.5V8C13.5 8.27614 13.7239 8.5 14 8.5H19.5L13.5 2.5Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'notifications'"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :fill="iconColor"
  >
    <path
      d="M9.04172 19.003H14.9583C14.7196 20.4211 13.486 21.5015 12 21.5015C10.514 21.5015 9.28037 20.4211 9.04172 19.003ZM12 2.00391C16.1421 2.00391 19.5 5.36177 19.5 9.50391V13.5024L20.9183 16.6625C20.9732 16.7848 21.0016 16.9174 21.0016 17.0515C21.0016 17.5762 20.5763 18.0015 20.0516 18.0015H3.95218C3.8184 18.0015 3.68612 17.9733 3.56402 17.9186C3.08514 17.7042 2.87073 17.1422 3.08511 16.6633L4.5 13.5028L4.50011 9.49099L4.50453 9.24107C4.6436 5.21035 7.95587 2.00391 12 2.00391Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'profiles'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7543 14.0002C18.9963 14.0002 20.0032 15.007 20.0032 16.2491V17.1675C20.0032 17.7409 19.8239 18.2999 19.4906 18.7664C17.9447 20.9296 15.4204 22.0013 12.0001 22.0013C8.57915 22.0013 6.05619 20.9292 4.51403 18.7648C4.18207 18.2989 4.00366 17.7411 4.00366 17.169V16.2491C4.00366 15.007 5.01052 14.0002 6.25254 14.0002H17.7543ZM12.0001 2.00488C14.7615 2.00488 17.0001 4.24346 17.0001 7.00488C17.0001 9.76631 14.7615 12.0049 12.0001 12.0049C9.2387 12.0049 7.00012 9.76631 7.00012 7.00488C7.00012 4.24346 9.2387 2.00488 12.0001 2.00488Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'darkmode'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0258 17.0014C17.2639 21.7851 11.1471 23.4241 6.3634 20.6622C5.06068 19.9101 3.964 18.8926 3.12872 17.6797C2.84945 17.2741 3.0301 16.7141 3.49369 16.5482C7.26112 15.1997 9.27892 13.6372 10.4498 11.4021C11.6825 9.04908 12.001 6.47162 11.1387 2.93862C11.0195 2.45008 11.4053 1.98492 11.9075 2.01186C13.4645 2.09539 14.9856 2.54263 16.3649 3.33903C21.1486 6.10088 22.7876 12.2177 20.0258 17.0014Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'logout'"
    :width="width"
    :height="height"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.31818 0C1.03789 0 0 1.07452 0 2.4V16.8C0 18.1255 1.03789 19.2 2.31818 19.2H8.03708C6.89596 18.1042 6.18182 16.5384 6.18182 14.8C6.18182 11.4863 8.77653 8.8 11.9773 8.8C12.6546 8.8 13.3049 8.92031 13.9091 9.14141V2.4C13.9091 1.07452 12.8712 0 11.5909 0H2.31818ZM3.47727 10.4C2.83712 10.4 2.31818 9.86274 2.31818 9.2C2.31818 8.53726 2.83712 8 3.47727 8C4.11742 8 4.63636 8.53726 4.63636 9.2C4.63636 9.86274 4.11742 10.4 3.47727 10.4ZM17 14.8C17 17.6719 14.7512 20 11.9773 20C9.2033 20 6.95455 17.6719 6.95455 14.8C6.95455 11.9281 9.2033 9.6 11.9773 9.6C14.7512 9.6 17 11.9281 17 14.8ZM8.88636 14.4C8.67298 14.4 8.5 14.5791 8.5 14.8C8.5 15.0209 8.67298 15.2 8.88636 15.2H14.1354L12.4768 16.9172C12.3259 17.0734 12.3259 17.3266 12.4768 17.4828C12.6277 17.6391 12.8723 17.6391 13.0232 17.4828L15.3414 15.0828C15.3784 15.0445 15.4064 15.0003 15.4252 14.9531C15.4438 14.9066 15.4542 14.8558 15.4545 14.8024L15.4545 14.8L15.4545 14.7976C15.454 14.696 15.4162 14.5947 15.3414 14.5172L13.0232 12.1172C12.8723 11.9609 12.6277 11.9609 12.4768 12.1172C12.3259 12.2734 12.3259 12.5266 12.4768 12.6828L14.1354 14.4H8.88636Z"
      fill="#5E6272"
    />
  </svg>
  <svg
    v-else-if="name === 'clock'"
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="#FFFFFF"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM7.5 3C7.22386 3 7 3.22386 7 3.5V8.5L7.00806 8.58988C7.05039 8.82312 7.25454 9 7.5 9H10.5L10.5899 8.99194C10.8231 8.94961 11 8.74546 11 8.5C11 8.22386 10.7761 8 10.5 8H8V3.5L7.99194 3.41012C7.94961 3.17688 7.74546 3 7.5 3Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'plus-circle'"
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'filter'"
    :width="width"
    :height="height"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H48C52.4183 0 56 3.58172 56 8V48C56 52.4183 52.4183 56 48 56H0V0Z"
      fill="#246BFD"
    />
    <path
      d="M20.2038 19H35.7962C36.6554 19 37.1146 20.0119 36.5488 20.6585L31.2474 26.7172C31.0879 26.8995 31 27.1335 31 27.3757V33.882C31 34.2607 30.786 34.607 30.4472 34.7764L26.4472 36.7764C25.7823 37.1088 25 36.6253 25 35.882V27.3757C25 27.1335 24.9121 26.8995 24.7526 26.7172L19.4512 20.6585C18.8854 20.0119 19.3446 19 20.2038 19Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>

  <svg
    v-else-if="name === 'pencil'"
    :width="width"
    :height="height"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.007 4.80913L4.05846 10.7591C3.7393 11.0782 3.33939 11.3047 2.9015 11.4141L0.619328 11.9847C0.254549 12.0759 -0.0758704 11.7455 0.0153245 11.3807L0.585868 9.0985C0.69534 8.66061 0.921762 8.2607 1.24093 7.94154L7.18946 1.99159L10.007 4.80913ZM11.4165 0.58353C12.1945 1.36157 12.1945 2.62302 11.4165 3.40106L10.7114 4.10474L7.89385 1.28721L8.59894 0.58353C9.37698 -0.19451 10.6384 -0.19451 11.4165 0.58353Z"
      fill="#F6C22D"
    />
  </svg>

  <svg
    v-else-if="name === 'eye'"
    :width="width"
    :height="height"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM7.98953 3C9.92253 3 11.4895 4.567 11.4895 6.5C11.4895 8.43299 9.92253 10 7.98953 10C6.05653 10 4.48953 8.43299 4.48953 6.5C4.48953 4.567 6.05653 3 7.98953 3Z"
      fill="#0CAC59"
    />
  </svg>

  <svg
    v-else-if="name === 'close-x'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12ZM16.2803 7.71967C16.5732 8.01256 16.5732 8.48744 16.2803 8.78033L13.0607 12L16.2803 15.2197C16.5732 15.5126 16.5732 15.9874 16.2803 16.2803C15.9874 16.5732 15.5126 16.5732 15.2197 16.2803L12 13.0607L8.78033 16.2803C8.48744 16.5732 8.01256 16.5732 7.71967 16.2803C7.42678 15.9874 7.42678 15.5126 7.71967 15.2197L10.9393 12L7.71967 8.78033C7.42678 8.48744 7.42678 8.01256 7.71967 7.71967C8.01256 7.42678 8.48744 7.42678 8.78033 7.71967L12 10.9393L15.2197 7.71967C15.5126 7.42678 15.9874 7.42678 16.2803 7.71967Z"
      fill="#231F20"
    />
  </svg>

  <svg
    v-else-if="name === 'calendar'"
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 0C1.11929 0 0 1.11929 0 2.5V3H14V2.5C14 1.11929 12.8807 0 11.5 0H2.5ZM14 4H0V11.5C0 12.8807 1.11929 14 2.5 14H11.5C12.8807 14 14 12.8807 14 11.5V4ZM5 7C5 7.55229 4.55228 8 4 8C3.44772 8 3 7.55229 3 7C3 6.44771 3.44772 6 4 6C4.55228 6 5 6.44771 5 7ZM4 11C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9C4.55228 9 5 9.44771 5 10C5 10.5523 4.55228 11 4 11ZM8 7C8 7.55229 7.55229 8 7 8C6.44771 8 6 7.55229 6 7C6 6.44771 6.44771 6 7 6C7.55229 6 8 6.44771 8 7ZM7 11C6.44771 11 6 10.5523 6 10C6 9.44771 6.44771 9 7 9C7.55229 9 8 9.44771 8 10C8 10.5523 7.55229 11 7 11ZM11 7C11 7.55229 10.5523 8 10 8C9.44771 8 9 7.55229 9 7C9 6.44771 9.44771 6 10 6C10.5523 6 11 6.44771 11 7Z"
      fill="#ED1C2D"
    />
  </svg>

  <svg
    v-else-if="name === 'create-group'"
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.87858 6.5C7.38298 5.88925 8.14603 5.5 9 5.5C9.85397 5.5 10.617 5.88925 11.1214 6.5C11.5141 6.97547 11.75 7.58519 11.75 8.25C11.75 9.74532 10.5565 10.9619 9.07018 10.9991C9.04686 10.9997 9.02346 11 9 11C8.97654 11 8.95315 10.9997 8.92983 10.9991C7.44347 10.9619 6.25 9.74532 6.25 8.25C6.25 7.58519 6.48591 6.97547 6.87858 6.5ZM12.75 8.25C12.75 7.61795 12.5936 7.02243 12.3175 6.5H16.5C17.3284 6.5 18 7.17157 18 8V8.5C18 10.0874 16.7944 11.7119 14.6851 12.2845C14.258 11.5183 13.4395 11 12.5 11H11.5495C12.2879 10.3151 12.75 9.33653 12.75 8.25ZM12.5 12C12.932 12 13.3214 12.1826 13.5951 12.4749C13.8462 12.7431 14 13.1036 14 13.5V14C14 15.9714 12.1405 18 9 18C5.85951 18 4 15.9714 4 14V13.5C4 13.1036 4.15376 12.7431 4.40489 12.4749C4.6786 12.1826 5.06797 12 5.5 12H12.5ZM0 8.5C0 10.0874 1.20563 11.7119 3.31486 12.2845C3.742 11.5183 4.56048 11 5.5 11H6.45047C5.71209 10.3151 5.25 9.33653 5.25 8.25C5.25 7.61795 5.40637 7.02243 5.68252 6.5H1.5C0.671573 6.5 0 7.17157 0 8V8.5ZM7.75 2.75C7.75 1.23122 6.51878 0 5 0C3.48122 0 2.25 1.23122 2.25 2.75C2.25 4.26878 3.48122 5.5 5 5.5C6.51878 5.5 7.75 4.26878 7.75 2.75ZM15.75 2.75C15.75 1.23122 14.5188 0 13 0C11.4812 0 10.25 1.23122 10.25 2.75C10.25 4.26878 11.4812 5.5 13 5.5C14.5188 5.5 15.75 4.26878 15.75 2.75Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'create-account'"
    :width="width"
    :height="height"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0ZM2.00873 9C0.903151 9 0 9.88687 0 11C0 12.6912 0.83281 13.9663 2.13499 14.7966C3.41697 15.614 5.14526 16 7 16C8.85474 16 10.583 15.614 11.865 14.7966C13.1672 13.9663 14 12.6912 14 11C14 9.89557 13.1045 9.00001 12 9.00001L2.00873 9Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'delete'"
    :width="width"
    :height="height"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.25C9.43458 0.25 10.6126 1.34848 10.7388 2.75019L15 2.75C15.4142 2.75 15.75 3.08579 15.75 3.5C15.75 3.8797 15.4678 4.19349 15.1018 4.24315L15 4.25H14.417L13.1499 15.2292C13.0335 16.2384 12.179 17 11.1631 17H4.83688C3.821 17 2.9665 16.2384 2.85006 15.2292L1.582 4.25H1C0.620304 4.25 0.306509 3.96785 0.256847 3.60177L0.25 3.5C0.25 3.1203 0.532154 2.80651 0.898229 2.75685L1 2.75L5.26119 2.75019C5.38741 1.34848 6.56542 0.25 8 0.25ZM6.5 6.5C6.25454 6.5 6.05039 6.65477 6.00806 6.85886L6 6.9375V13.0625L6.00806 13.1411C6.05039 13.3452 6.25454 13.5 6.5 13.5C6.74546 13.5 6.94961 13.3452 6.99194 13.1411L7 13.0625V6.9375L6.99194 6.85886C6.94961 6.65477 6.74546 6.5 6.5 6.5ZM9.5 6.5C9.25454 6.5 9.05039 6.65477 9.00806 6.85886L9 6.9375V13.0625L9.00806 13.1411C9.05039 13.3452 9.25454 13.5 9.5 13.5C9.74546 13.5 9.94961 13.3452 9.99194 13.1411L10 13.0625V6.9375L9.99194 6.85886C9.94961 6.65477 9.74546 6.5 9.5 6.5ZM8 1.75C7.39524 1.75 6.89079 2.17947 6.77499 2.75005H9.22501C9.10921 2.17947 8.60476 1.75 8 1.75Z"
      fill="#ED1C2D"
    />
  </svg>

  <svg
    v-else-if="name === 'eye-login'"
    :width="width"
    :height="height"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM7.98953 3C9.92253 3 11.4895 4.567 11.4895 6.5C11.4895 8.43299 9.92253 10 7.98953 10C6.05653 10 4.48953 8.43299 4.48953 6.5C4.48953 4.567 6.05653 3 7.98953 3Z"
      fill="#333333"
    />
  </svg>

  <svg
    v-else-if="name === 'eye-login-hide'"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.219669 0.21967C-0.0465965 0.485936 -0.0708046 0.9026 0.147051 1.19621L0.219669 1.28033L4.25424 5.3149C2.33225 6.66437 0.895775 8.67992 0.298883 11.0644C0.198299 11.4662 0.442495 11.8735 0.844311 11.9741C1.24613 12.0746 1.6534 11.8305 1.75399 11.4286C2.28346 9.31347 3.59112 7.53947 5.33416 6.39452L7.14379 8.20425C6.43628 8.92578 6 9.91426 6 11.0046C6 13.2138 7.79086 15.0046 10 15.0046C11.0904 15.0046 12.0788 14.5683 12.8004 13.8608L18.7197 19.7803C19.0126 20.0732 19.4874 20.0732 19.7803 19.7803C20.0466 19.5141 20.0708 19.0974 19.8529 18.8038L19.7803 18.7197L13.6668 12.6055L13.668 12.604L6.71877 5.65782L6.72 5.656L5.58672 4.52549L1.28033 0.21967C0.987438 -0.0732233 0.512562 -0.0732233 0.219669 0.21967ZM10 3.5C8.99974 3.5 8.02914 3.64807 7.11109 3.925L8.34807 5.16119C8.88386 5.05532 9.4364 5 10 5C13.9231 5 17.3099 7.68026 18.2471 11.4332C18.3475 11.835 18.7546 12.0794 19.1565 11.9791C19.5584 11.8787 19.8028 11.4716 19.7024 11.0697C18.5994 6.65272 14.6155 3.5 10 3.5ZM10.1947 7.00928L13.996 10.81C13.8942 8.75308 12.2472 7.10764 10.1947 7.00928Z"
      fill="#231F20"
    />
  </svg>

  <svg
    v-else-if="name === 'users'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 15C15.7165 15 16.5 15.7835 16.5 16.75L16.4989 17.7121C16.6156 19.9012 14.9879 21.009 12.0668 21.009C9.15786 21.009 7.5 19.9192 7.5 17.75V16.75C7.5 15.7835 8.2835 15 9.25 15H14.75ZM3.75 10L8.126 10.0001C8.04375 10.3197 8 10.6547 8 11C8 12.1155 8.45665 13.1244 9.1932 13.8499L9.35526 14.001L9.25 14C8.94865 14 8.65863 14.0485 8.38729 14.138C7.52255 14.4235 6.84765 15.1264 6.60122 16.0082L6.56679 16.009C3.65786 16.009 2 14.9192 2 12.75V11.75C2 10.7835 2.7835 10 3.75 10ZM20.25 10C21.2165 10 22 10.7835 22 11.75L21.9989 12.7121C22.1156 14.9012 20.4879 16.009 17.5668 16.009L17.3985 16.0073C17.1596 15.1534 16.5188 14.4673 15.6929 14.1659C15.4576 14.08 15.2073 14.0254 14.947 14.0069L14.75 14L14.6447 14.001C15.4758 13.268 16 12.1952 16 11C16 10.6547 15.9563 10.3197 15.874 10.0001L20.25 10ZM12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8ZM6.5 3C8.15685 3 9.5 4.34315 9.5 6C9.5 7.65685 8.15685 9 6.5 9C4.84315 9 3.5 7.65685 3.5 6C3.5 4.34315 4.84315 3 6.5 3ZM17.5 3C19.1569 3 20.5 4.34315 20.5 6C20.5 7.65685 19.1569 9 17.5 9C15.8431 9 14.5 7.65685 14.5 6C14.5 4.34315 15.8431 3 17.5 3Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'delete-large'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" rx="16" fill="#ED1C2D" fill-opacity="0.2" />
    <path
      d="M34 16.75C37.3751 16.75 40.1253 19.4252 40.2459 22.7709L40.25 23.001L47 23C47.9665 23 48.75 23.7835 48.75 24.75C48.75 25.6682 48.0429 26.4212 47.1435 26.4942L47 26.5H45.833L44.2058 48.0698C44.0385 50.2867 42.191 52 39.9679 52H28.0321C25.809 52 23.9615 50.2867 23.7942 48.0698L22.166 26.5H21C20.0818 26.5 19.3288 25.7929 19.2558 24.8935L19.25 24.75C19.25 23.8318 19.9571 23.0788 20.8565 23.0058L21 23H27.75C27.75 19.7016 30.305 17 33.5438 16.7664L33.7709 16.7541L34 16.75ZM37.75 29.75C37.1028 29.75 36.5705 30.2419 36.5065 30.8722L36.5 31V43L36.5065 43.1278C36.5705 43.7581 37.1028 44.25 37.75 44.25C38.3972 44.25 38.9295 43.7581 38.9935 43.1278L39 43V31L38.9935 30.8722C38.9295 30.2419 38.3972 29.75 37.75 29.75ZM30.25 29.75C29.6028 29.75 29.0705 30.2419 29.0065 30.8722L29 31V43L29.0065 43.1278C29.0705 43.7581 29.6028 44.25 30.25 44.25C30.8972 44.25 31.4295 43.7581 31.4935 43.1278L31.5 43V31L31.4935 30.8722C31.4295 30.2419 30.8972 29.75 30.25 29.75ZM34.1675 20.255L34 20.25C32.5375 20.25 31.3416 21.3917 31.255 22.8325L31.25 23.001L36.75 23C36.75 21.5375 35.6083 20.3416 34.1675 20.255Z"
      fill="#ED1C2D"
    />
  </svg>

  <svg
    v-else-if="name === 'btn-delete'"
    :width="width"
    :height="height"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.25C9.43458 0.25 10.6126 1.34848 10.7388 2.75019L15 2.75C15.4142 2.75 15.75 3.08579 15.75 3.5C15.75 3.8797 15.4678 4.19349 15.1018 4.24315L15 4.25H14.417L13.1499 15.2292C13.0335 16.2384 12.179 17 11.1631 17H4.83688C3.821 17 2.9665 16.2384 2.85006 15.2292L1.582 4.25H1C0.620304 4.25 0.306509 3.96785 0.256847 3.60177L0.25 3.5C0.25 3.1203 0.532154 2.80651 0.898229 2.75685L1 2.75L5.26119 2.75019C5.38741 1.34848 6.56542 0.25 8 0.25ZM6.5 6.5C6.25454 6.5 6.05039 6.65477 6.00806 6.85886L6 6.9375V13.0625L6.00806 13.1411C6.05039 13.3452 6.25454 13.5 6.5 13.5C6.74546 13.5 6.94961 13.3452 6.99194 13.1411L7 13.0625V6.9375L6.99194 6.85886C6.94961 6.65477 6.74546 6.5 6.5 6.5ZM9.5 6.5C9.25454 6.5 9.05039 6.65477 9.00806 6.85886L9 6.9375V13.0625L9.00806 13.1411C9.05039 13.3452 9.25454 13.5 9.5 13.5C9.74546 13.5 9.94961 13.3452 9.99194 13.1411L10 13.0625V6.9375L9.99194 6.85886C9.94961 6.65477 9.74546 6.5 9.5 6.5ZM8 1.75C7.39524 1.75 6.89079 2.17947 6.77499 2.75005H9.22501C9.10921 2.17947 8.60476 1.75 8 1.75Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'btn-disable'"
    :width="width"
    :height="height"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4C3 1.79086 4.79086 0 7 0C9.20914 0 11 1.79086 11 4C11 6.20914 9.20914 8 7 8C4.79086 8 3 6.20914 3 4ZM0 11C0 9.88687 0.903151 9 2.00873 9L8.25716 9C7.47182 9.95094 7 11.1704 7 12.5C7 13.8031 7.45316 15.0003 8.21047 15.9427C7.8155 15.9811 7.41085 16 7 16C5.14526 16 3.41697 15.614 2.13499 14.7966C0.83281 13.9663 0 12.6912 0 11ZM17 12.5C17 14.9853 14.9853 17 12.5 17C10.0147 17 8 14.9853 8 12.5C8 10.0147 10.0147 8 12.5 8C14.9853 8 17 10.0147 17 12.5ZM14.3536 11.3536C14.5488 11.1583 14.5488 10.8417 14.3536 10.6464C14.1583 10.4512 13.8417 10.4512 13.6464 10.6464L12.5 11.7929L11.3536 10.6464C11.1583 10.4512 10.8417 10.4512 10.6464 10.6464C10.4512 10.8417 10.4512 11.1583 10.6464 11.3536L11.7929 12.5L10.6464 13.6464C10.4512 13.8417 10.4512 14.1583 10.6464 14.3536C10.8417 14.5488 11.1583 14.5488 11.3536 14.3536L12.5 13.2071L13.6464 14.3536C13.8417 14.5488 14.1583 14.5488 14.3536 14.3536C14.5488 14.1583 14.5488 13.8417 14.3536 13.6464L13.2071 12.5L14.3536 11.3536Z"
      fill="white"
    />
  </svg>
  <svg
    v-else-if="name === 'btn-enable'"
    :width="width"
    :height="height"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4C3 1.79086 4.79086 0 7 0C9.20914 0 11 1.79086 11 4C11 6.20914 9.20914 8 7 8C4.79086 8 3 6.20914 3 4ZM0 11C0 9.88687 0.903151 9 2.00873 9L8.25716 9C7.47182 9.95094 7 11.1704 7 12.5C7 13.8031 7.45316 15.0003 8.21047 15.9427C7.8155 15.9811 7.41085 16 7 16C5.14526 16 3.41697 15.614 2.13499 14.7966C0.83281 13.9663 0 12.6912 0 11ZM17 12.5C17 14.9853 14.9853 17 12.5 17C10.0147 17 8 14.9853 8 12.5C8 10.0147 10.0147 8 12.5 8C14.9853 8 17 10.0147 17 12.5ZM14.8536 10.6464C14.6583 10.4512 14.3417 10.4512 14.1464 10.6464L11.5 13.2929L10.8536 12.6464C10.6583 12.4512 10.3417 12.4512 10.1464 12.6464C9.95118 12.8417 9.95118 13.1583 10.1464 13.3536L11.1464 14.3536C11.3417 14.5488 11.6583 14.5488 11.8536 14.3536L14.8536 11.3536C15.0488 11.1583 15.0488 10.8417 14.8536 10.6464Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'btn-search'"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 0C8.53757 0 11 2.46243 11 5.5C11 6.74832 10.5841 7.89951 9.88336 8.82257L14.0303 12.9697C14.3232 13.2626 14.3232 13.7374 14.0303 14.0303C13.7641 14.2966 13.3474 14.3208 13.0538 14.1029L12.9697 14.0303L8.82257 9.88336C7.89951 10.5841 6.74832 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0ZM5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5Z"
      fill="white"
    />
  </svg>
  <svg
    v-else-if="name === 'btn-reset-filter'"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.897052 1.05379L0.96967 0.96967C1.23594 0.703403 1.6526 0.679197 1.94621 0.897052L2.03033 0.96967L7 5.939L11.9697 0.96967C12.2359 0.703403 12.6526 0.679197 12.9462 0.897052L13.0303 0.96967C13.2966 1.23594 13.3208 1.6526 13.1029 1.94621L13.0303 2.03033L8.061 7L13.0303 11.9697C13.2966 12.2359 13.3208 12.6526 13.1029 12.9462L13.0303 13.0303C12.7641 13.2966 12.3474 13.3208 12.0538 13.1029L11.9697 13.0303L7 8.061L2.03033 13.0303C1.76406 13.2966 1.3474 13.3208 1.05379 13.1029L0.96967 13.0303C0.703403 12.7641 0.679197 12.3474 0.897052 12.0538L0.96967 11.9697L5.939 7L0.96967 2.03033C0.703403 1.76406 0.679197 1.3474 0.897052 1.05379L0.96967 0.96967L0.897052 1.05379Z"
      fill="white"
    />
  </svg>
  <svg
    v-else-if="name === 'warring'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" rx="16" fill="#F6C22D" fill-opacity="0.2" />
    <path
      d="M31.8197 16.5488C33.8975 15.4286 36.4915 16.0795 37.7661 17.9886L37.9316 18.2567L53.437 45.5032C53.8063 46.1522 54 46.8818 54 47.6237C54 49.9399 52.1512 51.8359 49.8115 51.9899L49.5036 52H18.4972C17.7352 52 16.9857 51.8115 16.3191 51.4522C14.2411 50.3323 13.4316 47.8469 14.4179 45.7824L14.5636 45.5037L30.0647 18.2571C30.4734 17.5387 31.0816 16.9467 31.8197 16.5488ZM34.0018 42.2614C32.8996 42.2614 32.0061 43.1311 32.0061 44.2039C32.0061 45.2766 32.8996 46.1463 34.0018 46.1463C35.104 46.1463 35.9975 45.2766 35.9975 44.2039C35.9975 43.1311 35.104 42.2614 34.0018 42.2614ZM33.9975 26.6939C32.9726 26.6943 32.1283 27.4454 32.0132 28.4128L31.9998 28.6396L32.0034 38.3665L32.0169 38.5934C32.1327 39.5607 32.9777 40.3112 34.0025 40.3109C35.0274 40.3105 35.8717 39.5593 35.9868 38.592L36.0002 38.3651L35.9966 28.6382L35.9831 28.4114C35.8673 27.4441 35.0223 26.6936 33.9975 26.6939Z"
      fill="#F6C22D"
    />
  </svg>
  <svg
    v-else-if="name === 'close-no-circle'"
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.897052 1.05379L0.96967 0.96967C1.23594 0.703403 1.6526 0.679197 1.94621 0.897052L2.03033 0.96967L7 5.939L11.9697 0.96967C12.2359 0.703403 12.6526 0.679197 12.9462 0.897052L13.0303 0.96967C13.2966 1.23594 13.3208 1.6526 13.1029 1.94621L13.0303 2.03033L8.061 7L13.0303 11.9697C13.2966 12.2359 13.3208 12.6526 13.1029 12.9462L13.0303 13.0303C12.7641 13.2966 12.3474 13.3208 12.0538 13.1029L11.9697 13.0303L7 8.061L2.03033 13.0303C1.76406 13.2966 1.3474 13.3208 1.05379 13.1029L0.96967 13.0303C0.703403 12.7641 0.679197 12.3474 0.897052 12.0538L0.96967 11.9697L5.939 7L0.96967 2.03033C0.703403 1.76406 0.679197 1.3474 0.897052 1.05379L0.96967 0.96967L0.897052 1.05379Z"
      fill="#FFFFFF"
    />
  </svg>
  <svg
    v-else-if="name === 'person-delete'"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 6C5 3.79086 6.79086 2 9 2C11.2091 2 13 3.79086 13 6C13 8.20914 11.2091 10 9 10C6.79086 10 5 8.20914 5 6ZM2 13C2 11.8869 2.90315 11 4.00873 11L10.2572 11C9.47182 11.9509 9 13.1704 9 14.5C9 15.8031 9.45316 17.0003 10.2105 17.9427C9.8155 17.9811 9.41085 18 9 18C7.14526 18 5.41697 17.614 4.13499 16.7966C2.83281 15.9663 2 14.6912 2 13ZM19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM16.3536 13.3536C16.5488 13.1583 16.5488 12.8417 16.3536 12.6464C16.1583 12.4512 15.8417 12.4512 15.6464 12.6464L14.5 13.7929L13.3536 12.6464C13.1583 12.4512 12.8417 12.4512 12.6464 12.6464C12.4512 12.8417 12.4512 13.1583 12.6464 13.3536L13.7929 14.5L12.6464 15.6464C12.4512 15.8417 12.4512 16.1583 12.6464 16.3536C12.8417 16.5488 13.1583 16.5488 13.3536 16.3536L14.5 15.2071L15.6464 16.3536C15.8417 16.5488 16.1583 16.5488 16.3536 16.3536C16.5488 16.1583 16.5488 15.8417 16.3536 15.6464L15.2071 14.5L16.3536 13.3536Z"
      fill="#C84C0F"
    />
  </svg>
  <svg
    v-else-if="name === 'icon-popup-closing'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
  >
    <rect width="68" height="68" rx="16" fill="#F6C22D" fill-opacity="0.2" />
    <path
      d="M34 14C45.0457 14 54 22.9543 54 34C54 45.0457 45.0457 54 34 54C22.9543 54 14 45.0457 14 34C14 22.9543 22.9543 14 34 14ZM27.8839 26.1161L27.7824 26.025C27.3266 25.6583 26.6734 25.6583 26.2176 26.025L26.1161 26.1161L26.025 26.2176C25.6583 26.6734 25.6583 27.3266 26.025 27.7824L26.1161 27.8839L32.233 34L26.1161 40.1161L26.025 40.2176C25.6583 40.6734 25.6583 41.3266 26.025 41.7824L26.1161 41.8839L26.2176 41.975C26.6734 42.3417 27.3266 42.3417 27.7824 41.975L27.8839 41.8839L34 35.767L40.1161 41.8839L40.2176 41.975C40.6734 42.3417 41.3266 42.3417 41.7824 41.975L41.8839 41.8839L41.975 41.7824C42.3417 41.3266 42.3417 40.6734 41.975 40.2176L41.8839 40.1161L35.767 34L41.8839 27.8839L41.975 27.7824C42.3417 27.3266 42.3417 26.6734 41.975 26.2176L41.8839 26.1161L41.7824 26.025C41.3266 25.6583 40.6734 25.6583 40.2176 26.025L40.1161 26.1161L34 32.233L27.8839 26.1161L27.7824 26.025L27.8839 26.1161Z"
      fill="#F6C22D"
    />
  </svg>
  <svg
    v-else-if="name === 'icon-popup-delete'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
  >
    <rect width="68" height="68" rx="16" fill="#ED1C2D" fill-opacity="0.2" />
    <path
      d="M34 16.75C37.3751 16.75 40.1253 19.4252 40.2459 22.7709L40.25 23.001L47 23C47.9665 23 48.75 23.7835 48.75 24.75C48.75 25.6682 48.0429 26.4212 47.1435 26.4942L47 26.5H45.833L44.2058 48.0698C44.0385 50.2867 42.191 52 39.9679 52H28.0321C25.809 52 23.9615 50.2867 23.7942 48.0698L22.166 26.5H21C20.0818 26.5 19.3288 25.7929 19.2558 24.8935L19.25 24.75C19.25 23.8318 19.9571 23.0788 20.8565 23.0058L21 23H27.75C27.75 19.7016 30.305 17 33.5438 16.7664L33.7709 16.7541L34 16.75ZM37.75 29.75C37.1028 29.75 36.5705 30.2419 36.5065 30.8722L36.5 31V43L36.5065 43.1278C36.5705 43.7581 37.1028 44.25 37.75 44.25C38.3972 44.25 38.9295 43.7581 38.9935 43.1278L39 43V31L38.9935 30.8722C38.9295 30.2419 38.3972 29.75 37.75 29.75ZM30.25 29.75C29.6028 29.75 29.0705 30.2419 29.0065 30.8722L29 31V43L29.0065 43.1278C29.0705 43.7581 29.6028 44.25 30.25 44.25C30.8972 44.25 31.4295 43.7581 31.4935 43.1278L31.5 43V31L31.4935 30.8722C31.4295 30.2419 30.8972 29.75 30.25 29.75ZM34.1675 20.255L34 20.25C32.5375 20.25 31.3416 21.3917 31.255 22.8325L31.25 23.001L36.75 23C36.75 21.5375 35.6083 20.3416 34.1675 20.255Z"
      fill="#ED1C2D"
    />
  </svg>
  <svg
    v-else-if="name === 'icon-popup-submit'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
  >
    <rect width="68" height="68" rx="16" fill="#0CAC59" fill-opacity="0.2" />
    <path
      d="M34 14C45.0457 14 54 22.9543 54 34C54 45.0457 45.0457 54 34 54C22.9543 54 14 45.0457 14 34C14 22.9543 22.9543 14 34 14ZM42.6339 27.6161C42.1783 27.1605 41.4585 27.1301 40.9676 27.525L40.8661 27.6161L30.75 37.7322L27.1339 34.1161C26.6457 33.628 25.8543 33.628 25.3661 34.1161C24.9105 34.5717 24.8801 35.2915 25.275 35.7824L25.3661 35.8839L29.8661 40.3839C30.3217 40.8395 31.0416 40.8699 31.5324 40.475L31.6339 40.3839L42.6339 29.3839C43.122 28.8957 43.122 28.1043 42.6339 27.6161Z"
      fill="#0CAC59"
    />
  </svg>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="name === 'check-mark-circle'"
  >
    <path
      d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM13.3584 7.64645C13.1849 7.47288 12.9154 7.4536 12.7206 7.58859L12.6513 7.64645L9 11.298L7.35355 9.65131L7.28431 9.59346C7.08944 9.45846 6.82001 9.47775 6.64645 9.65131C6.47288 9.82488 6.4536 10.0943 6.58859 10.2892L6.64645 10.3584L8.64645 12.3584L8.71569 12.4163C8.8862 12.5344 9.1138 12.5344 9.28431 12.4163L9.35355 12.3584L13.3584 8.35355L13.4163 8.28431C13.5513 8.08944 13.532 7.82001 13.3584 7.64645Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'rental'"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" rx="6" fill="#F6C22D" fill-opacity="0.2" />
    <path
      d="M29.75 29.375C29.75 29.7202 30.0298 30 30.375 30C30.3961 30 30.4169 29.999 30.4375 29.9969C30.4581 29.999 30.4789 30 30.5 30H31.75C32.0952 30 32.375 29.7202 32.375 29.375C32.375 29.0298 32.0952 28.75 31.75 28.75H31V27.125C31 26.7798 30.7202 26.5 30.375 26.5C30.0298 26.5 29.75 26.7798 29.75 27.125V29.375ZM22 22.5C22 21.1193 23.1193 20 24.5 20H36C37.3807 20 38.5 21.1193 38.5 22.5V36.75C38.5 37.1642 38.1642 37.5 37.75 37.5H23.5C23.5 38.0523 23.9477 38.5 24.5 38.5H37.75C38.1642 38.5 38.5 38.8358 38.5 39.25C38.5 39.6642 38.1642 40 37.75 40H24.5C23.1193 40 22 38.8807 22 37.5V22.5ZM30.375 24.0049C27.7516 24.0049 25.625 26.1315 25.625 28.7549C25.625 31.3782 27.7516 33.5049 30.375 33.5049C32.9984 33.5049 35.125 31.3782 35.125 28.7549C35.125 26.1315 32.9984 24.0049 30.375 24.0049Z"
      fill="#F6C22D"
    />
  </svg>
  <svg
    v-else-if="name === 'jobcost'"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" rx="6" fill="#246BFD" fill-opacity="0.2" />
    <path
      d="M29.75 29.375C29.75 29.7202 30.0298 30 30.375 30C30.3961 30 30.4169 29.999 30.4375 29.9969C30.4581 29.999 30.4789 30 30.5 30H31.75C32.0952 30 32.375 29.7202 32.375 29.375C32.375 29.0298 32.0952 28.75 31.75 28.75H31V27.125C31 26.7798 30.7202 26.5 30.375 26.5C30.0298 26.5 29.75 26.7798 29.75 27.125V29.375ZM22 22.5C22 21.1193 23.1193 20 24.5 20H36C37.3807 20 38.5 21.1193 38.5 22.5V36.75C38.5 37.1642 38.1642 37.5 37.75 37.5H23.5C23.5 38.0523 23.9477 38.5 24.5 38.5H37.75C38.1642 38.5 38.5 38.8358 38.5 39.25C38.5 39.6642 38.1642 40 37.75 40H24.5C23.1193 40 22 38.8807 22 37.5V22.5ZM30.375 24.0049C27.7516 24.0049 25.625 26.1315 25.625 28.7549C25.625 31.3782 27.7516 33.5049 30.375 33.5049C32.9984 33.5049 35.125 31.3782 35.125 28.7549C35.125 26.1315 32.9984 24.0049 30.375 24.0049Z"
      fill="#246BFD"
    />
  </svg>
  <svg
    v-else-if="name === 'unbilled'"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" rx="6" fill="#7B61FF" fill-opacity="0.2" />
    <path
      d="M29.75 29.375C29.75 29.7202 30.0298 30 30.375 30C30.3961 30 30.4169 29.999 30.4375 29.9969C30.4581 29.999 30.4789 30 30.5 30H31.75C32.0952 30 32.375 29.7202 32.375 29.375C32.375 29.0298 32.0952 28.75 31.75 28.75H31V27.125C31 26.7798 30.7202 26.5 30.375 26.5C30.0298 26.5 29.75 26.7798 29.75 27.125V29.375ZM22 22.5C22 21.1193 23.1193 20 24.5 20H36C37.3807 20 38.5 21.1193 38.5 22.5V36.75C38.5 37.1642 38.1642 37.5 37.75 37.5H23.5C23.5 38.0523 23.9477 38.5 24.5 38.5H37.75C38.1642 38.5 38.5 38.8358 38.5 39.25C38.5 39.6642 38.1642 40 37.75 40H24.5C23.1193 40 22 38.8807 22 37.5V22.5ZM30.375 24.0049C27.7516 24.0049 25.625 26.1315 25.625 28.7549C25.625 31.3782 27.7516 33.5049 30.375 33.5049C32.9984 33.5049 35.125 31.3782 35.125 28.7549C35.125 26.1315 32.9984 24.0049 30.375 24.0049Z"
      fill="#7B61FF"
    />
  </svg>
  <svg
    v-else-if="name === 'logout-header'"
    :width="width"
    :height="height"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.31818 0C1.03789 0 0 1.07452 0 2.4V16.8C0 18.1255 1.03789 19.2 2.31818 19.2H8.03708C6.89596 18.1042 6.18182 16.5384 6.18182 14.8C6.18182 11.4863 8.77653 8.8 11.9773 8.8C12.6546 8.8 13.3049 8.92031 13.9091 9.14141V2.4C13.9091 1.07452 12.8712 0 11.5909 0H2.31818ZM3.47727 10.4C2.83712 10.4 2.31818 9.86274 2.31818 9.2C2.31818 8.53726 2.83712 8 3.47727 8C4.11742 8 4.63636 8.53726 4.63636 9.2C4.63636 9.86274 4.11742 10.4 3.47727 10.4ZM17 14.8C17 17.6719 14.7512 20 11.9773 20C9.2033 20 6.95455 17.6719 6.95455 14.8C6.95455 11.9281 9.2033 9.6 11.9773 9.6C14.7512 9.6 17 11.9281 17 14.8ZM8.88636 14.4C8.67298 14.4 8.5 14.5791 8.5 14.8C8.5 15.0209 8.67298 15.2 8.88636 15.2H14.1354L12.4768 16.9172C12.3259 17.0734 12.3259 17.3266 12.4768 17.4828C12.6277 17.6391 12.8723 17.6391 13.0232 17.4828L15.3414 15.0828C15.3784 15.0445 15.4064 15.0003 15.4252 14.9531C15.4438 14.9066 15.4542 14.8558 15.4545 14.8024L15.4545 14.8L15.4545 14.7976C15.454 14.696 15.4162 14.5947 15.3414 14.5172L13.0232 12.1172C12.8723 11.9609 12.6277 11.9609 12.4768 12.1172C12.3259 12.2734 12.3259 12.5266 12.4768 12.6828L14.1354 14.4H8.88636Z"
      :fill="iconColor"
    />
  </svg>
  <svg
    :width="width"
    :height="height"
    v-else-if="name === 'arrow-left-bold'"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70711 0.292893C9.09763 0.683418 9.09763 1.31658 8.70711 1.70711L2.41421 8L8.70711 14.2929C9.09763 14.6834 9.09763 15.3166 8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292894 8.70711C-0.0976312 8.31658 -0.0976312 7.68342 0.292894 7.29289L7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    :width="width"
    :height="height"
    v-else-if="name === 'arrow-right-bold'"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L6.58579 8L0.292894 14.2929C-0.0976305 14.6834 -0.0976305 15.3166 0.292894 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'arrow-left'"
    :width="width"
    :height="height"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70711 0.292893C9.09763 0.683418 9.09763 1.31658 8.70711 1.70711L2.41421 8L8.70711 14.2929C9.09763 14.6834 9.09763 15.3166 8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292894 8.70711C-0.0976312 8.31658 -0.0976312 7.68342 0.292894 7.29289L7.29289 0.292893C7.68342 -0.0976311 8.31658 -0.0976311 8.70711 0.292893Z"
      :fill="iconColor"
    />
  </svg>
  <svg
    v-else-if="name === 'arrow-right'"
    :width="width"
    :height="height"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L6.58579 8L0.292894 14.2929C-0.0976305 14.6834 -0.0976305 15.3166 0.292894 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'day-shift'"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 0C10.3946 0 10.7144 0.31979 10.7144 0.714272V2.14282C10.7144 2.5373 10.3946 2.85709 10.0001 2.85709C9.6056 2.85709 9.28581 2.5373 9.28581 2.14282V0.714272C9.28581 0.31979 9.6056 0 10.0001 0ZM15 9.99981C15 12.7612 12.7615 14.9997 10.0001 14.9997C7.23869 14.9997 5.00013 12.7612 5.00013 9.99981C5.00013 7.23844 7.23869 4.9999 10.0001 4.9999C12.7615 4.9999 15 7.23844 15 9.99981ZM19.2857 10.7141C19.6802 10.7141 20 10.3943 20 9.99981C20 9.60533 19.6802 9.28554 19.2857 9.28554H17.8572C17.4627 9.28554 17.1429 9.60533 17.1429 9.99981C17.1429 10.3943 17.4627 10.7141 17.8572 10.7141H19.2857ZM10.0001 17.1425C10.3946 17.1425 10.7144 17.4623 10.7144 17.8568V19.2857C10.7144 19.6802 10.3946 20 10.0001 20C9.6056 20 9.28581 19.6802 9.28581 19.2857V17.8568C9.28581 17.4623 9.6056 17.1425 10.0001 17.1425ZM2.14302 10.7141C2.5375 10.7141 2.8573 10.3943 2.8573 9.99981C2.8573 9.60533 2.5375 9.28554 2.14302 9.28554H0.714279C0.319794 9.28554 0 9.60533 0 9.99981C0 10.3943 0.319794 10.7141 0.714279 10.7141H2.14302ZM2.35292 2.35259C2.63187 2.07364 3.08412 2.07364 3.36307 2.35259L4.79162 3.78113C5.07057 4.06007 5.07057 4.51232 4.79162 4.79126C4.51268 5.0702 4.06042 5.0702 3.78148 4.79126L2.35292 3.36272C2.07398 3.08378 2.07398 2.63153 2.35292 2.35259ZM3.36307 17.6482C3.08412 17.9272 2.63187 17.9272 2.35292 17.6482C2.07398 17.3693 2.07398 16.917 2.35292 16.6381L3.78148 15.2095C4.06042 14.9306 4.51268 14.9306 4.79162 15.2095C5.07057 15.4885 5.07057 15.9407 4.79162 16.2197L3.36307 17.6482ZM17.6487 2.35259C17.3697 2.07364 16.9175 2.07364 16.6385 2.35259L15.2099 3.78113C14.931 4.06007 14.931 4.51232 15.2099 4.79126C15.4889 5.0702 15.9411 5.0702 16.2201 4.79126L17.6487 3.36272C17.9276 3.08378 17.9276 2.63153 17.6487 2.35259ZM16.6385 17.6482C16.9175 17.9272 17.3697 17.9272 17.6487 17.6482C17.9276 17.3693 17.9276 16.917 17.6487 16.6381L16.2201 15.2095C15.9411 14.9306 15.4889 14.9306 15.2099 15.2095C14.931 15.4885 14.931 15.9407 15.2099 16.2197L16.6385 17.6482Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'night-shift'"
    :width="width"
    :height="height"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6855 14.9958C13.9795 19.7811 7.98623 21.4207 3.29925 18.6579C2.02286 17.9055 0.948344 16.8877 0.129947 15.6743C-0.143676 15.2687 0.0333188 14.7084 0.487537 14.5424C4.17882 13.1936 6.15585 11.6304 7.30308 9.39457C8.51082 7.04077 8.82294 4.46242 7.97805 0.928195C7.86122 0.439489 8.23929 -0.0258342 8.7313 0.00111641C10.2568 0.0846783 11.7471 0.532076 13.0987 1.32874C17.7856 4.09155 19.3915 10.2105 16.6855 14.9958Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'trending'"
    :width="width"
    :height="height"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 0L11.2982 0.00144196L11.4058 0.015934L11.519 0.0491095L11.6206 0.0968704L11.6592 0.120347L11.7079 0.154787L11.784 0.221784L11.8682 0.322976L11.9266 0.422698L11.9681 0.528118L11.9912 0.625475L12.0015 0.75V5.25389C12.0015 5.6681 11.6657 6.00389 11.2515 6.00389C10.8718 6.00389 10.558 5.72174 10.5084 5.35566L10.5015 5.25389L10.501 2.561L6.28044 6.78411C6.01407 7.0506 5.59709 7.07473 5.30344 6.85658L5.21931 6.78386L3.74855 5.31112L1.28044 7.78022C0.987601 8.07318 0.512728 8.07327 0.219776 7.78044C-0.0465439 7.51422 -0.0708331 7.09756 0.146963 6.80391L0.219564 6.71978L3.21836 3.71978C3.48474 3.45329 3.90171 3.42916 4.19536 3.64731L4.27949 3.72003L5.75024 5.19276L9.441 1.5L6.75 1.5C6.3703 1.5 6.05651 1.21785 6.00685 0.85177L6 0.75C6 0.335786 6.33579 0 6.75 0Z"
      fill="#0CAC59"
    />
  </svg>

  <svg
    v-else-if="name === 'downtrend'"
    :width="width"
    :height="height"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74914 8L11.2967 7.99856L11.4043 7.98407L11.5175 7.95089L11.6191 7.90313L11.6577 7.87965L11.7064 7.84521L11.7825 7.77822L11.8667 7.67702L11.925 7.5773L11.9666 7.47188L11.9897 7.37452L12 7.25V2.74611C12 2.3319 11.6643 1.99611 11.2501 1.99611C10.8704 1.99611 10.5567 2.27826 10.507 2.64434L10.5002 2.74611L10.4997 5.439L6.27965 1.21589C6.0133 0.949403 5.59638 0.925266 5.30276 1.14342L5.21865 1.21614L3.74807 2.68888L1.28027 0.219776C0.987476 -0.0731754 0.512662 -0.0732708 0.219748 0.219563C-0.046538 0.485777 -0.0708241 0.902436 0.146944 1.19609L0.219536 1.28022L3.21796 4.28022C3.4843 4.54671 3.90121 4.57084 4.19483 4.35269L4.27894 4.27997L5.74951 2.80724L9.4398 6.5L6.74914 6.5C6.36949 6.5 6.05574 6.78215 6.00608 7.14823L5.99924 7.25C5.99924 7.66421 6.33498 8 6.74914 8Z"
      fill="#ED1C2D"
    />
  </svg>

  <svg
    v-else-if="name === 'close-no-circle-red'"
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.897052 1.05379L0.96967 0.96967C1.23594 0.703403 1.6526 0.679197 1.94621 0.897052L2.03033 0.96967L7 5.939L11.9697 0.96967C12.2359 0.703403 12.6526 0.679197 12.9462 0.897052L13.0303 0.96967C13.2966 1.23594 13.3208 1.6526 13.1029 1.94621L13.0303 2.03033L8.061 7L13.0303 11.9697C13.2966 12.2359 13.3208 12.6526 13.1029 12.9462L13.0303 13.0303C12.7641 13.2966 12.3474 13.3208 12.0538 13.1029L11.9697 13.0303L7 8.061L2.03033 13.0303C1.76406 13.2966 1.3474 13.3208 1.05379 13.1029L0.96967 13.0303C0.703403 12.7641 0.679197 12.3474 0.897052 12.0538L0.96967 11.9697L5.939 7L0.96967 2.03033C0.703403 1.76406 0.679197 1.3474 0.897052 1.05379L0.96967 0.96967L0.897052 1.05379Z"
      fill="#ED1C2D"
    />
  </svg>

  <svg
    v-else-if="name === 'canceling-red'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" rx="16" fill="#ED1C2D" fill-opacity="0.2" />
    <path
      d="M34 14C45.0457 14 54 22.9543 54 34C54 45.0457 45.0457 54 34 54C22.9543 54 14 45.0457 14 34C14 22.9543 22.9543 14 34 14ZM27.8839 26.1161L27.7824 26.025C27.3266 25.6583 26.6734 25.6583 26.2176 26.025L26.1161 26.1161L26.025 26.2176C25.6583 26.6734 25.6583 27.3266 26.025 27.7824L26.1161 27.8839L32.233 34L26.1161 40.1161L26.025 40.2176C25.6583 40.6734 25.6583 41.3266 26.025 41.7824L26.1161 41.8839L26.2176 41.975C26.6734 42.3417 27.3266 42.3417 27.7824 41.975L27.8839 41.8839L34 35.767L40.1161 41.8839L40.2176 41.975C40.6734 42.3417 41.3266 42.3417 41.7824 41.975L41.8839 41.8839L41.975 41.7824C42.3417 41.3266 42.3417 40.6734 41.975 40.2176L41.8839 40.1161L35.767 34L41.8839 27.8839L41.975 27.7824C42.3417 27.3266 42.3417 26.6734 41.975 26.2176L41.8839 26.1161L41.7824 26.025C41.3266 25.6583 40.6734 25.6583 40.2176 26.025L40.1161 26.1161L34 32.233L27.8839 26.1161L27.7824 26.025L27.8839 26.1161Z"
      fill="#ED1C2D"
    />
  </svg>
  <svg
    v-else-if="name === 'canceling-warning'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" rx="16" fill="#F6C22D" fill-opacity="0.2" />
    <path
      d="M34 14C45.0457 14 54 22.9543 54 34C54 45.0457 45.0457 54 34 54C22.9543 54 14 45.0457 14 34C14 22.9543 22.9543 14 34 14ZM27.8839 26.1161L27.7824 26.025C27.3266 25.6583 26.6734 25.6583 26.2176 26.025L26.1161 26.1161L26.025 26.2176C25.6583 26.6734 25.6583 27.3266 26.025 27.7824L26.1161 27.8839L32.233 34L26.1161 40.1161L26.025 40.2176C25.6583 40.6734 25.6583 41.3266 26.025 41.7824L26.1161 41.8839L26.2176 41.975C26.6734 42.3417 27.3266 42.3417 27.7824 41.975L27.8839 41.8839L34 35.767L40.1161 41.8839L40.2176 41.975C40.6734 42.3417 41.3266 42.3417 41.7824 41.975L41.8839 41.8839L41.975 41.7824C42.3417 41.3266 42.3417 40.6734 41.975 40.2176L41.8839 40.1161L35.767 34L41.8839 27.8839L41.975 27.7824C42.3417 27.3266 42.3417 26.6734 41.975 26.2176L41.8839 26.1161L41.7824 26.025C41.3266 25.6583 40.6734 25.6583 40.2176 26.025L40.1161 26.1161L34 32.233L27.8839 26.1161L27.7824 26.025L27.8839 26.1161Z"
      fill="#F6C22D"
    />
  </svg>
  <svg
    v-else-if="name === 'view-logs'"
    :width="width"
    :height="height"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM7.98953 3C9.92253 3 11.4895 4.567 11.4895 6.5C11.4895 8.43299 9.92253 10 7.98953 10C6.05653 10 4.48953 8.43299 4.48953 6.5C4.48953 4.567 6.05653 3 7.98953 3Z"
      fill="#246BFD"
    />
  </svg>
  <svg
    v-else-if="name === 'timesheet-tracking'"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="iconColor"
  >
    <path
      d="M13.75 2H10.25C9.09205 2 8.13841 2.87472 8.01379 3.99944L6.25 4C5.00736 4 4 5.00736 4 6.25V19.75C4 20.9926 5.00736 22 6.25 22H17.75C18.9926 22 20 20.9926 20 19.75V6.25C20 5.00736 18.9926 4 17.75 4L15.9862 3.99944C15.8616 2.87472 14.9079 2 13.75 2ZM10.25 3.5H13.75C14.1642 3.5 14.5 3.83579 14.5 4.25C14.5 4.66421 14.1642 5 13.75 5H10.25C9.83579 5 9.5 4.66421 9.5 4.25C9.5 3.83579 9.83579 3.5 10.25 3.5ZM12.5 10.25C12.5 9.83579 12.8358 9.5 13.25 9.5H16.75C17.1642 9.5 17.5 9.83579 17.5 10.25C17.5 10.6642 17.1642 11 16.75 11H13.25C12.8358 11 12.5 10.6642 12.5 10.25ZM13.2501 15H16.7499C17.1642 15 17.4999 15.3358 17.4999 15.75C17.4999 16.1642 17.1642 16.5 16.7499 16.5H13.2501C12.8358 16.5 12.5001 16.1642 12.5001 15.75C12.5001 15.3358 12.8358 15 13.2501 15ZM10.7803 9.78033L8.78033 11.7803C8.48744 12.0732 8.01256 12.0732 7.71967 11.7803L6.71967 10.7803C6.42678 10.4874 6.42678 10.0126 6.71967 9.71967C7.01256 9.42678 7.48744 9.42678 7.78033 9.71967L8.25 10.1893L9.71967 8.71967C10.0126 8.42678 10.4874 8.42678 10.7803 8.71967C11.0732 9.01256 11.0732 9.48744 10.7803 9.78033ZM10.7803 14.2197C11.0732 14.5126 11.0732 14.9874 10.7803 15.2803L8.78033 17.2803C8.48744 17.5732 8.01256 17.5732 7.71967 17.2803L6.71967 16.2803C6.42678 15.9874 6.42678 15.5126 6.71967 15.2197C7.01256 14.9268 7.48744 14.9268 7.78033 15.2197L8.25 15.6893L9.71967 14.2197C10.0126 13.9268 10.4874 13.9268 10.7803 14.2197Z"
      fill="#9398A0"
    />
  </svg>

  <svg
    v-else-if="name === 'collapse-down'"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z"
    ></path>
  </svg>

  <svg
    v-else-if="name === 'mail-box'"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18 5.5C18 7.98528 15.9853 10 13.5 10C11.0147 10 9 7.98528 9 5.5C9 3.01472 11.0147 1 13.5 1C15.9853 1 18 3.01472 18 5.5ZM13.8539 3.1469L13.8514 3.14439C13.804 3.0975 13.7495 3.06203 13.6914 3.03799C13.6333 3.01391 13.5697 3.00046 13.503 3.00006L13.5 3.00006L13.497 3.00006C13.4303 3.00046 13.3667 3.01391 13.3086 3.03799C13.2496 3.06239 13.1944 3.09856 13.1464 3.1465L11.1464 5.1465C10.9512 5.34176 10.9512 5.65835 11.1464 5.85361C11.3417 6.04887 11.6583 6.04887 11.8535 5.85361L13 4.70716V7.50006C13 7.7762 13.2238 8.00006 13.5 8.00006C13.7761 8.00006 14 7.7762 14 7.50006L14 4.70716L15.1464 5.85361C15.3417 6.04887 15.6583 6.04887 15.8535 5.85361C16.0488 5.65835 16.0488 5.34176 15.8535 5.1465L13.8539 3.1469ZM16 10.4003V11H12.5C12.2245 11 12.0011 11.2228 12 11.498L12 11.4991L11.9997 11.5114C11.9993 11.5238 11.9983 11.5443 11.9964 11.5718C11.9925 11.6269 11.9843 11.7088 11.9677 11.8084C11.9341 12.0101 11.8679 12.2713 11.7403 12.5264C11.6137 12.7796 11.4317 13.0176 11.168 13.1933C10.9074 13.3671 10.5375 13.5 10 13.5C9.46249 13.5 9.0926 13.3671 8.83204 13.1933C8.56834 13.0176 8.38631 12.7796 8.25971 12.5264C8.13214 12.2713 8.06586 12.0101 8.03226 11.8084C8.01565 11.7088 8.00755 11.6269 8.00361 11.5718C8.00165 11.5443 8.00075 11.5238 8.00033 11.5114L8.00001 11.4991C7.99968 11.2233 7.77594 11 7.5 11H4V7C4 5.89543 4.89543 5 6 5H8.02242C8.05337 4.65659 8.11588 4.32228 8.20703 4H6C4.34315 4 3 5.34315 3 7V15C3 16.6569 4.34315 18 6 18H14C15.6569 18 17 16.6569 17 15V9.74284C16.6929 9.99647 16.3578 10.2174 16 10.4003Z"
      fill="white"
    ></path>
  </svg>

  <svg
    v-else-if="name === 'btn-fix'"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" rx="16" fill="#ED1C2D" fill-opacity="0.2" />
    <path
      d="M31.8197 16.5488C33.8975 15.4286 36.4915 16.0795 37.7661 17.9886L37.9316 18.2567L53.437 45.5032C53.8063 46.1522 54 46.8818 54 47.6237C54 49.9399 52.1512 51.8359 49.8115 51.9899L49.5036 52H18.4972C17.7352 52 16.9857 51.8115 16.3191 51.4522C14.2411 50.3323 13.4316 47.8469 14.4179 45.7824L14.5636 45.5037L30.0647 18.2571C30.4734 17.5387 31.0816 16.9467 31.8197 16.5488ZM34.0018 42.2614C32.8996 42.2614 32.0061 43.1311 32.0061 44.2039C32.0061 45.2766 32.8996 46.1463 34.0018 46.1463C35.104 46.1463 35.9975 45.2766 35.9975 44.2039C35.9975 43.1311 35.104 42.2614 34.0018 42.2614ZM33.9975 26.6939C32.9726 26.6943 32.1283 27.4454 32.0132 28.4128L31.9998 28.6396L32.0034 38.3665L32.0169 38.5934C32.1327 39.5607 32.9777 40.3112 34.0025 40.3109C35.0274 40.3105 35.8717 39.5593 35.9868 38.592L36.0002 38.3651L35.9966 28.6382L35.9831 28.4114C35.8673 27.4441 35.0223 26.6936 33.9975 26.6939Z"
      fill="#ED1C2D"
    />
  </svg>

  <svg
    v-else-if="name === 'double-people'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 14L13.5 14.001C14.2793 14.001 14.9204 14.5963 14.9931 15.3566L15 15.501V17.5C14.999 21 11.284 22 8.5 22C5.77787 22 2.1647 21.044 2.00545 17.7296L2 17.5V15.5C2 14.7207 2.59527 14.0796 3.35561 14.0069L3.5 14ZM15.488 14H20.5C21.2793 14 21.9204 14.5944 21.9931 15.3555L22 15.5V17C21.999 20.062 19.142 21 17 21C16.32 21 15.569 20.904 14.86 20.678C15.5128 19.9277 15.9362 18.9748 15.9934 17.78L16 17.5V15.5C16 15.0056 15.8507 14.5488 15.601 14.1616L15.488 14H20.5H15.488ZM8.5 3C10.985 3 13 5.015 13 7.5C13 9.985 10.985 12 8.5 12C6.015 12 4 9.985 4 7.5C4 5.015 6.015 3 8.5 3ZM17.5 5C19.433 5 21 6.567 21 8.5C21 10.433 19.433 12 17.5 12C15.567 12 14 10.433 14 8.5C14 6.567 15.567 5 17.5 5Z"
      :fill="iconColor"
    />
  </svg>

  <svg
    v-else-if="name === 'mail-box-2'"
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M43.0002 16.976V33.75C43.0002 36.6495 40.6497 39 37.7502 39H10.2502C7.35074 39 5 36.6495 5 33.75V16.976L23.3978 27.0953C23.7729 27.3016 24.2275 27.3016 24.6026 27.0953L43.0002 16.976ZM37.7502 9C40.6076 9 42.9319 11.2828 42.9988 14.1241L24.0002 24.5734L5.002 14.124L5.00461 14.0336C5.1181 11.2344 7.42323 9 10.2502 9H37.7502Z"
      fill="#0CAC59"
    />
  </svg>

  <svg
    v-else-if="name === 'send-mail-success'"
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
  >
    <path
      d="M59.5 2C27.542 2 1.5 28.042 1.5 60C1.5 91.958 27.542 118 59.5 118C91.458 118 117.5 91.958 117.5 60C117.5 28.042 91.458 2 59.5 2ZM87.224 46.66L54.338 79.546C53.526 80.358 52.424 80.822 51.264 80.822C50.104 80.822 49.002 80.358 48.19 79.546L31.776 63.132C30.094 61.45 30.094 58.666 31.776 56.984C33.458 55.302 36.242 55.302 37.924 56.984L51.264 70.324L81.076 40.512C82.758 38.83 85.542 38.83 87.224 40.512C88.906 42.194 88.906 44.92 87.224 46.66Z"
      fill="#0CAC59"
    />
  </svg>

  <svg
    v-else-if="name === 'send-mail-error'"
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
  >
    <path
      d="M60 3C91.4802 3 117 28.5198 117 60C117 91.4802 91.4802 117 60 117C28.5198 117 3 91.4802 3 60C3 28.5198 28.5198 3 60 3ZM42.5691 37.5309L42.2798 37.2712C40.9808 36.2263 39.1192 36.2263 37.8202 37.2712L37.5309 37.5309L37.2712 37.8202C36.2263 39.1192 36.2263 40.9808 37.2712 42.2798L37.5309 42.5691L54.964 60L37.5309 77.4309L37.2712 77.7202C36.2263 79.0192 36.2263 80.8808 37.2712 82.1798L37.5309 82.4691L37.8202 82.7288C39.1192 83.7737 40.9808 83.7737 42.2798 82.7288L42.5691 82.4691L60 65.0359L77.4309 82.4691L77.7202 82.7288C79.0192 83.7737 80.8808 83.7737 82.1798 82.7288L82.4691 82.4691L82.7288 82.1798C83.7737 80.8808 83.7737 79.0192 82.7288 77.7202L82.4691 77.4309L65.0359 60L82.4691 42.5691L82.7288 42.2798C83.7737 40.9808 83.7737 39.1192 82.7288 37.8202L82.4691 37.5309L82.1798 37.2712C80.8808 36.2263 79.0192 36.2263 77.7202 37.2712L77.4309 37.5309L60 54.964L42.5691 37.5309L42.2798 37.2712L42.5691 37.5309Z"
      fill="#ED1C2D"
    />
  </svg>

  <svg
    v-else-if="name === 'arrow-date'"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    :fill="iconColor"
  >
    <g opacity="0.4">
      <path
        d="M2 8.00001C2 7.58579 2.33579 7.25001 2.75 7.25001L11.537 7.25L8.24991 4.30894C7.94122 4.03274 7.91488 3.5586 8.19107 3.24991C8.46727 2.94122 8.94141 2.91488 9.2501 3.19108L14.0001 7.44107C14.1591 7.58335 14.25 7.78663 14.25 8C14.25 8.21338 14.1591 8.41666 14.0001 8.55894L9.2501 12.8089C8.94141 13.0851 8.46727 13.0588 8.19107 12.7501C7.91488 12.4414 7.94122 11.9673 8.24991 11.6911L11.537 8.75L2.75 8.75001C2.33579 8.75001 2 8.41422 2 8.00001Z"
        :fill="iconColor"
      />
    </g>
  </svg>

  <svg
    v-else-if="name === 'settings'"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="iconColor"
  >
    <path
      d="M12.0122 2.25C12.7462 2.25846 13.4773 2.34326 14.1937 2.50304C14.5064 2.57279 14.7403 2.83351 14.7758 3.15196L14.946 4.67881C15.0231 5.37986 15.615 5.91084 16.3206 5.91158C16.5103 5.91188 16.6979 5.87238 16.8732 5.79483L18.2738 5.17956C18.5651 5.05159 18.9055 5.12136 19.1229 5.35362C20.1351 6.43464 20.8889 7.73115 21.3277 9.14558C21.4223 9.45058 21.3134 9.78203 21.0564 9.9715L19.8149 10.8866C19.4607 11.1468 19.2516 11.56 19.2516 11.9995C19.2516 12.4389 19.4607 12.8521 19.8157 13.1129L21.0582 14.0283C21.3153 14.2177 21.4243 14.5492 21.3297 14.8543C20.8911 16.2685 20.1377 17.5649 19.1261 18.6461C18.9089 18.8783 18.5688 18.9483 18.2775 18.8206L16.8712 18.2045C16.4688 18.0284 16.0068 18.0542 15.6265 18.274C15.2463 18.4937 14.9933 18.8812 14.945 19.3177L14.7759 20.8444C14.741 21.1592 14.5122 21.4182 14.204 21.4915C12.7556 21.8361 11.2465 21.8361 9.79803 21.4915C9.48991 21.4182 9.26105 21.1592 9.22618 20.8444L9.05736 19.32C9.00777 18.8843 8.75434 18.498 8.37442 18.279C7.99451 18.06 7.5332 18.0343 7.1322 18.2094L5.72557 18.8256C5.43422 18.9533 5.09403 18.8833 4.87678 18.6509C3.86462 17.5685 3.11119 16.2705 2.6732 14.8548C2.57886 14.5499 2.68786 14.2186 2.94485 14.0293L4.18818 13.1133C4.54232 12.8531 4.75147 12.4399 4.75147 12.0005C4.75147 11.561 4.54232 11.1478 4.18771 10.8873L2.94516 9.97285C2.6878 9.78345 2.5787 9.45178 2.67337 9.14658C3.11212 7.73215 3.86594 6.43564 4.87813 5.35462C5.09559 5.12236 5.43594 5.05259 5.72724 5.18056L7.12762 5.79572C7.53056 5.97256 7.9938 5.94585 8.37577 5.72269C8.75609 5.50209 9.00929 5.11422 9.05817 4.67764L9.22824 3.15196C9.26376 2.83335 9.49786 2.57254 9.8108 2.50294C10.5281 2.34342 11.26 2.25865 12.0122 2.25ZM11.9997 8.99995C10.3428 8.99995 8.9997 10.3431 8.9997 12C8.9997 13.6568 10.3428 15 11.9997 15C13.6565 15 14.9997 13.6568 14.9997 12C14.9997 10.3431 13.6565 8.99995 11.9997 8.99995Z"
      :fill="iconColor"
    />
  </svg>
  <svg
    v-else-if="name === 'reassign'"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.27774 14.8393C3.10829 14.702 3 14.4849 3 14.25C2.99904 14.0571 3.07226 13.8704 3.21966 13.7232L6.22797 10.7193C6.52086 10.4269 6.99574 10.4269 7.28863 10.7193C7.58152 11.0118 7.58152 11.486 7.28863 11.7784L5.55589 13.5L16.25 13.5C16.6642 13.5 17 13.8364 17 14.25C17 14.6636 16.6642 15 16.25 15L5.56639 15L7.28412 16.7216C7.57701 17.014 7.57701 17.4882 7.28411 17.7807C6.99122 18.0731 6.51635 18.0731 6.22345 17.7807L3.27774 14.8393ZM16.7222 6.33931C16.8917 6.202 17 5.98488 17 5.75C17.001 5.55709 16.9277 5.37038 16.7803 5.2232L13.772 2.21934C13.4791 1.92688 13.0043 1.92688 12.7114 2.21934C12.4185 2.5118 12.4185 2.98597 12.7114 3.27843L14.4441 5L3.74999 5C3.33578 5 2.99999 5.3364 2.99999 5.75C2.99999 6.1636 3.33578 6.5 3.74999 6.5L14.4336 6.5L12.7159 8.22157C12.423 8.51403 12.423 8.9882 12.7159 9.28066C13.0088 9.57311 13.4836 9.57311 13.7765 9.28066L16.7222 6.33931Z"
      fill="#0CAC59"
    />
  </svg>
  <svg
    :width="width"
    :height="height"
    v-else-if="name === 'user-tracking'"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 10C12.1046 10 13 10.8954 13 12H12.5C11.6716 12 11 12.6716 11 13.5V16.3174C10.0958 16.7527 8.91115 17 7.5 17C4.08805 17 2 15.5544 2 13.5V12C2 10.8954 2.89543 10 4 10H11ZM17.5 12C17.6753 12 17.8436 12.0301 18 12.0854V12C18 10.8954 17.1046 10 16 10L13.2353 9.99912L13.3723 10.1634C13.7658 10.6709 14 11.3081 14 12H17.5ZM7.5 2C9.433 2 11 3.567 11 5.5C11 7.433 9.433 9 7.5 9C5.567 9 4 7.433 4 5.5C4 3.567 5.567 2 7.5 2ZM14.5 4C15.8807 4 17 5.11929 17 6.5C17 7.88071 15.8807 9 14.5 9C13.1193 9 12 7.88071 12 6.5C12 5.11929 13.1193 4 14.5 4ZM12.5 13C12.2239 13 12 13.2239 12 13.5C12 13.7761 12.2239 14 12.5 14H17.5C17.7761 14 18 13.7761 18 13.5C18 13.2239 17.7761 13 17.5 13H12.5ZM12.5 15C12.2239 15 12 15.2239 12 15.5C12 15.7761 12.2239 16 12.5 16H17.5C17.7761 16 18 15.7761 18 15.5C18 15.2239 17.7761 15 17.5 15H12.5ZM12.5 17C12.2239 17 12 17.2239 12 17.5C12 17.7761 12.2239 18 12.5 18H17.5C17.7761 18 18 17.7761 18 17.5C18 17.2239 17.7761 17 17.5 17H12.5Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script lang="ts" setup>
import { ref, PropType, defineComponent } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  width: {
    type: [Number, String],
    default: 20,
  },
  height: {
    type: [Number, String],
    default: 20,
  },
  disabled: {
    type: [Boolean],
    default: false,
  },
  active: {
    type: [Boolean],
    default: false,
  },
  iconColor: {
    type: String,
    default: "#262A34",
  },
});

const color_1 = "#ED1C2D";
const color_2 = "#FFFFFF";
const color_3 = "#5E6272";
</script>
