﻿import env from "core/env";

export const imageEndpoint: string = env("VITE_VUE_APP_IMAGE_ENDPOINT") ?? "";

export const firebaseConfig = {
  apiKey: "AIzaSyA6SZbGnRKB5Uo3z-B_yjQT6S8fPnn8roE",
  authDomain: "emeco-timesheets-prod.firebaseapp.com",
  projectId: "emeco-timesheets-prod",
  storageBucket: "emeco-timesheets-prod.appspot.com",
  messagingSenderId: "339673293686",
  appId: "1:339673293686:web:ff2671af63b343640a1057",
  measurementId: "G-ZQSV22BWES",
};
