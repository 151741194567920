<template>
  <el-dialog
    v-model="dialogVisible"
    title="New Timesheet"
    width="628px"
    :close-on-click-modal="false"
    :before-close="handleClose"
    @open="handleOpen"
    top="40px"
    class="p-10"
  >
    <DetailTask
      ref="refDetailTask"
      :isEdit="false"
      :shift="shift"
      :isTouched="isTouched"
      :dialogVisible="dialogVisible"
      :isEndTimePM="EndTimePM"
      :totalHour="currentUser.userType == USER_TYPE_STAFF ? totalHour : totalHourSuper"
      @changeTouched="onChangeTouched"
      @changeEndTimePM="onChangeEndTimePM"
      @changeStartTimeAM="onChangeStartTimeAM"
      @changeShift= "onChangeShift"
      @changeValidTimeTask="handleChangeValidTimeTask"
      @changeMaxTimeMultipleTasks="handleMaxTimeMultipleTasks"
      @handlePopupOverLap="handlePopupOverLap"
      @handleAllTaskIsValid="handleAllTaskIsValid"
      @handleCheckNightShift="handleCheckNightShift"
      @handleCheckDayShift="handleCheckDayShift"
      @handleCancelSubmit="handleCancelSubmit"
    >
    </DetailTask>
    <div class="text-right pt-[20px]">
      <el-button @click="handleClose()" class="btn-cancel">Close</el-button>
      <el-button @click="handleSave()" class="btn-save" >Save</el-button>
      <el-button @click="handleSubmit()" class="btn-submit">Submit</el-button>
    </div>
  </el-dialog>

  <el-dialog
    v-model="dialogCheckCondition"
    :width="352"
    align-center
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="grid justify-items-center pt-4">
      <div class="mb-[24px]">
        <icon-svg :name="(dayShift && !popupOverlap) ? 'warring' : 'btn-fix'" />
      </div>
      <div class="text-xl font-medium mb-[16px]">{{ messageConfirm.type }}</div>
      <p class="text-base font-bold text-center whitespace-pre-line px-3">
        {{ messageConfirm.message }}
      </p>
    </div>
    <template #footer>
      <div class="flex justify-center mt-4 mb-4 ...">
        <el-button
          class="justify-self-center btn-cancel w-[83px] ml-[16px]"
          @click="handleClosePopupConfirm()"
          :class="messageConfirm.textButtonConfirm == 'None' ? ' bg-[#ED1C2D]' : 'bg-[#0CAC59]'"
        >
          <span class="font-bold text-[#fff]">{{ messageConfirm.textButtonCancel }}</span>
        </el-button>
        <el-button
          class="justify-self-center delete mr-0 btn-confirm pl-[32px] ml-[16px] pr-[32px] bg-[#ED1C2D]"
          @click="(dayShift) ? handleSaveTimeSheet() : handleClosePopupConfirm()"
          :class="messageConfirm.textButtonConfirm == 'None' ? 'hidden' : ''"
          v-if="onClickSave"
        >
          <el-icon>
            <icon-svg width="10" height="10" :name="'check-mark-circle'" />
          </el-icon>
          <span class="ml-[6px] font-bold">{{ messageConfirm.textButtonConfirm }}</span>
        </el-button>
        <el-button
          class="justify-self-center delete mr-0 btn-confirm pl-[32px] ml-[16px] pr-[32px] bg-[#ED1C2D]"
          @click="(dayShift) ? handleClickConfirmSubmit() : handleClosePopupConfirm()"
          :class="messageConfirm.textButtonConfirm == 'None' ? 'hidden' : ''"
          v-else
        >
          <el-icon>
            <icon-svg width="10" height="10" :name="'check-mark-circle'" />
          </el-icon>
          <span class="ml-[6px] font-bold">{{ messageConfirm.textButtonConfirm }}</span>
        </el-button>
      </div>
    </template>
  </el-dialog>
  <PopupNightShiftPrompt  :type="'Submitting'" :message="'Your timesheet will be submitted.\nAre you sure?'"
    :lblBtnSubmit="'Yes'" :lblBtnCancel="'No'" :iconPopup="'icon-popup-submit'" :iconBtnName="'check-mark-circle'" :width="400"
    @submit="handlePopupSubmitTimeSheet" @close="handleCloseSubmitTimeSheet" ref="popupConfirmSubmit" >
  </PopupNightShiftPrompt>


  <div class="el-dialog-popup">
    <PopupConfirm
    :type="'Closing'"
    :message="'Your data won’t be saved.\n Are you sure?'"
    :lblBtnSubmit="'Yes'"
    :iconPopup="'warring'"
    :iconBtnName="'check-mark-circle'"
    @submit="handleCloseDialogTask"
    ref="popupConfirmCloseTab"
    ></PopupConfirm>
  </div>
</template>
<script lang="ts">
import {computed, ComputedRef, defineComponent, reactive, Ref, ref, toRefs, watch} from "vue";
import {useStore} from "vuex";
import DetailTask from "@/components/DetailTask.vue";
import {Actions} from "modules/dashboard/store/types";
import PopupConfirm from "@/components/PopupConfirm.vue";
import PopupNightShiftPrompt from "@/components/PopupNightShiftPrompt.vue";
import { USER_TYPE_STAFF } from "core/constants";
import IconSvg from "@/layouts/IconBase.vue";
import dayjs from "dayjs";

interface MessageConfirm {
  message: string;
  textButtonConfirm: string;
  textButtonCancel: string;
  type: string;
  icon: string;
}

export default defineComponent({
  name: "AddNewTask",
  props: {},
  components: {
    DetailTask,
    PopupConfirm,
    PopupNightShiftPrompt,
    IconSvg
  },
  setup(props) {
    const store = useStore();
    const isOpenPopupAddTask = computed(() => store.state.isOpenPopupAddTask);
    const dialogVisible = ref<boolean>(false);
    const isTouched = ref<boolean>(false);
    const StartTimeAM = ref<number>(510);
    const EndTimePM = ref<number>(-1);
    const shift = ref<boolean>(false)
    const refDetailTask = ref<InstanceType<typeof DetailTask> | null>();
    const popupConfirmSubmit = ref<InstanceType<typeof PopupConfirm>>()
    const popupConfirmCloseTab = ref<InstanceType<typeof PopupConfirm>>();
    const popupConfirmSave = ref<InstanceType<typeof PopupConfirm>>();
    const totalHourMultipleTasks = ref<number>(0);
    const totalHour = computed(() => store.getters["dashboard/totalHour"])
    const totalHourSuper = computed(() =>store.getters['timeSheetsApprove/totalHour']);
    const currentUser = computed(() =>store.getters['auth/currentUser']);
    const messageValidTime = ref<string>('');
    const popupOverlap = ref<boolean>(false)
    const allTaskIsValid = ref<boolean>(false);
    const isClickSubmit = ref<boolean>(false);
    const dialogCheckCondition = ref<boolean>(false)
    const messageConfirm = ref<MessageConfirm>({
      message: 'Your timesheet will be submitted.\nAre you sure?',
      textButtonConfirm: 'Yes',
      textButtonCancel: 'No',
      type: 'Confirm',
      icon: 'btn-fix'
    });

    const resetDefault = () => {
      shift.value = false
      StartTimeAM.value = 510
      EndTimePM.value = -1
      isClickSubmit.value = false
    }
    
    const changeNightShiftFlag = () => {
      shift.value = true
      dialogCheckCondition.value = false
      isClickSubmit.value = false
    }

    const onClickSave = ref<boolean>(false);
    const nightShift = ref<boolean>(false)
    const dayShift = ref<boolean>(false)

    const handleCheckNightShift = (value: boolean) => {
      nightShift.value = value
    }

    const handleCheckDayShift = (value: boolean) => {
      dayShift.value = value
    }

    const checkNightShiftMismatchWarning = (textType: string, textIcon: string) => {
      if (nightShift.value) {
        return {
          message: "You have entered time for a Day shift that\n passes midnight. Please change the\n shift type to 'Night Shift' or correct the\n task time before saving or submitting.",
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon
        }
      }
    }
		const checkDayShiftMismatchWarning = (textType: string, textIcon: string) => {
      if (dayShift.value) {
        return {
          message: 'You have entered time for a Night shift \nthat passes midday.\n Please confirm this is accurate \nor go back and correct the timesheet.',
          textButtonConfirm: 'Confirm',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon

        }
      }
    }
		const checkCurrentTimesheetTotalHours = (textType: string, textIcon: string) => {
      if (totalHourMultipleTasks.value > 960) {
        return  {
          message: 'Total hours logged in the timesheet\n is over 16 hours.\n Please review and adjust your tasks\n accordingly.',
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon
        }
      }
    }
		const checkOverlapWarning = (textType: string, textIcon: string) => {
      if (popupOverlap.value) {
        return {
          message: 'Overlapping tasks detected in the\n timesheet. Ensure there are no conflicts\n in your specified task times.',
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon
        }
      }
    }
    const checkTimesheetTotalHours = (textType: string, textIcon: string) => {
      if ((totalHourMultipleTasks.value + (totalHour.value)) > 960) {
        return {
          message: 'The combined total hours for all\n timesheets on this day exceed 16 hours.\n Please review and adjust your\n timesheets.',
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon
        }
      }
    }

    const handleSave = async () => {
      onClickSave.value = true
      // const checkNight = checkNightShiftMismatchWarning('Confirm', 'btn-fix');
      // if (checkNight) {
      //   messageConfirm.value = checkNight
      //   dialogCheckCondition.value = true;
      //   return
      // }

      // const checktotalHour = checkCurrentTimesheetTotalHours('Confirm', 'btn-fix');
      // if (checktotalHour) {
      //   messageConfirm.value = checktotalHour
      //   dialogCheckCondition.value = true;
      //   return
      // }

      const checkOverlap = checkOverlapWarning('Confirm', 'btn-fix');
      if (checkOverlap) {
        messageConfirm.value = checkOverlap
        dialogCheckCondition.value = true;
        return
      }

      // const checkDay = checkDayShiftMismatchWarning('Confirm', 'btn-fix');
      // if (checkDay) {
      //   messageConfirm.value = checkDay;
      //   dialogCheckCondition.value = true;
      //   return
      // }

      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      await new Promise((f) => setTimeout(f, 200));
      store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.SAVE);
      dialogCheckCondition.value = false
      resetDefault()
    };

    const handleValidateForm = async () => {
      try {
        store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
        await new Promise((f) => setTimeout(f, 200));
        store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.CONFIRM);
        isClickSubmit.value = true
      } catch (error) {
        console.log('error', error)
      }
    }

    watch([allTaskIsValid, isClickSubmit], async () => {
      await new Promise((f) => setTimeout(f, 200));
      if (allTaskIsValid.value && isClickSubmit.value) {
        popupConfirmSubmit.value.showDialog = true
      }
    })

    const handlePopupSubmitTimeSheet = async () => {
      store.commit('auth/SET_IS_VALID', true);
      popupConfirmSubmit.value.showDialog = false
      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      await new Promise((f) => setTimeout(f, 200));
      store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.CONFIRM);
      resetDefault()
    }

    const handleSubmit = async () => {
      // const checkNight = checkNightShiftMismatchWarning('Submitting', 'check-mark-circle');
      // if (checkNight) {
      //   messageConfirm.value = checkNight
      //   dialogCheckCondition.value = true;
      //   return
      // }

      // const checkCurrentHour = checkCurrentTimesheetTotalHours('Submitting', 'check-mark-circle');
      // if (checkCurrentHour) {
      //   messageConfirm.value = checkCurrentHour
      //   dialogCheckCondition.value = true;
      //   return
      // }

      const checkOverlap = checkOverlapWarning('Submitting', 'check-mark-circle');
      if (checkOverlap) {
        messageConfirm.value = checkOverlap
        dialogCheckCondition.value = true;
        return
      }
      
      // const checkTotalHours = checkTimesheetTotalHours('Submitting', 'check-mark-circle');
      // if (checkTotalHours) {
      //   messageConfirm.value = checkTotalHours
      //   dialogCheckCondition.value = true;
      //   return
      // }
      
      // const checkDay = checkDayShiftMismatchWarning('Submitting', 'check-mark-circle');
      // if (checkDay) {
      //   messageConfirm.value = checkDay;
      //   dialogCheckCondition.value = true;
      //   return
      // }
      handleValidateForm();
    }

    const handleClose = () => {
      if(isTouched.value){
        popupConfirmCloseTab.value.showDialog = true
      } else {
        handleCloseDialogTask()
      }
    };

    const handleCloseDialogTask = () => {
      refDetailTask.value.resetData();
      store.commit("SET_OPEN_POPUP_ADD_TASK", false);
      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      popupConfirmCloseTab.value.showDialog = false;
      isTouched.value = false
      resetDefault()
      onClickSave.value = false
    };
    
    const handleOpen = () => {
      const currentDay = dayjs().format("YYYY-MM-DD")
      refDetailTask.value.resetData();
      store.dispatch("dashboard/loadCountTotalTimesheet", { date: currentDay})
    }
    const onChangeTouched = (value: any) => {
      isTouched.value = value
    }
    const onChangeEndTimePM = (value: any) => {
      EndTimePM.value = value
    }
    const onChangeStartTimeAM = (value: any) => {
      StartTimeAM.value = value
    }
    const onChangeShift = (value: boolean) => {
      shift.value = value
    }

    watch(isOpenPopupAddTask, () => {
      dialogVisible.value = isOpenPopupAddTask.value;
    });

    const handleChangeValidTimeTask = (messageError: string) => {
      if (messageError) {
        messageValidTime.value = messageError
      } else {
        messageValidTime.value = ''
      }
    }

    const handleMaxTimeMultipleTasks = (value: number) => {
      totalHourMultipleTasks.value = value;
    }

    const handlePopupOverLap = (error: boolean) => {
      if (error) {
        popupOverlap.value = true
      } else {
        popupOverlap.value = false
      }
    }

    const handleAllTaskIsValid = (value: boolean) => {
      allTaskIsValid.value = value
    }
    
    const handleClosePopupConfirm = () => {
      dialogCheckCondition.value = false
      isClickSubmit.value = false
      onClickSave.value = false
    }

    const handleCloseSubmitTimeSheet = () => {
      isClickSubmit.value = false
      allTaskIsValid.value = false
    }

    const handleClickConfirmSubmit = () => {
      dialogCheckCondition.value = false
      handleValidateForm()
    } 

    const handleSaveTimeSheet = async () => {
      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      await new Promise((f) => setTimeout(f, 200));
      store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.SAVE);
      dialogCheckCondition.value = false
      onClickSave.value = false
    }

    const handleCancelSubmit = (value: boolean) => {
      isClickSubmit.value = false
    }

    return {
      isTouched,
      dialogVisible,
      handleClose,
      handleSave,
      refDetailTask,
      handlePopupSubmitTimeSheet,
      handleCloseDialogTask,
      popupConfirmSubmit,
      popupConfirmCloseTab,
      handleOpen,
      onChangeTouched,
      EndTimePM,
      onChangeEndTimePM,
      onChangeShift,
      shift,
      onChangeStartTimeAM,
      popupConfirmSave,
      StartTimeAM,
      handleChangeValidTimeTask,
      messageValidTime,
      handleMaxTimeMultipleTasks,
      totalHourMultipleTasks,
      totalHour,
      changeNightShiftFlag,
      totalHourSuper,
      currentUser,
      USER_TYPE_STAFF,
      handlePopupOverLap,
      popupOverlap,
      handleAllTaskIsValid,
      handleClosePopupConfirm,
      messageConfirm,
      dialogCheckCondition,
      handleSubmit,
      isClickSubmit,
      handleCloseSubmitTimeSheet,
      handleClickConfirmSubmit,
      handleCheckNightShift,
      handleCheckDayShift,
      nightShift,
      dayShift,
      onClickSave,
      handleSaveTimeSheet,
      handleCancelSubmit
    };
  },
});
</script>
<style lang="scss">
.el-dialog__title {
  color: #231F20;
}
.btn-save {
  width: 97px;
}

.btn-submit {
  width: 112px;
}

.createTimeSheet {
  padding: 40px;
}

.dark {
  .el-button.btn-submit {
    background: #246BFD;
    border: none;
    &:hover,
    &:focus {
      background: #246BFD !important;
    }
  }
  .el-button.btn-save {
    background: #0CAC59;
    border: none;

    &:hover,
    &:focus {
      background: #0CAC59 !important;
    }
  }
}
</style>